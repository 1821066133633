import React from 'react';
import { Box, Container, Heading, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { socialLinks } from './constants';

const faqItems = [
	{
		question: "Do I own the content I create?",
		answer: (
			<>
				Yes, you own and are responsible for all the content you create using our platform. You are free to post it anywhere or use it however you like. However, please review the{' '}
				<ChakraLink as={RouterLink} to="/contentpolicy" color="blue.500">
					content policy
				</ChakraLink>{' '}
				for more information on how the image generator can be used.
			</>
		)
	},
	{
		question: "What should I do if I encounter a problem?",
		answer: (
			<>
				If you experience any issues or have concerns, please visit our{' '}
				<ChakraLink as={RouterLink} to="/help" color="blue.500">
					help page
				</ChakraLink>{' '}
				for assistance.
			</>
		)
	},
	{
		question: "How can I give you feedback on the site?",
		answer: (
			<>
				You can either email us at spherify@spherify.ai or you can provide feedback on our{' '}
				<ChakraLink href={socialLinks[0].url} isExternal color="blue.500">
					Discord server
				</ChakraLink>.
			</>
		)
	},
	// Add more FAQ items as needed
];

const FAQPage = () => {
	return (
		<Container maxW="container.md" py={8}>
			<VStack spacing={2} align="stretch">
				<Heading as="h2" size="lg" mt={6}>
					Frequently Asked Questions
				</Heading>
				<Accordion allowMultiple>
					{faqItems.map((item, index) => (
						<AccordionItem key={index}>
							<h3>
								<AccordionButton>
									<Box flex="1" textAlign="left">
										{item.question}
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h3>
							<AccordionPanel pb={4}>
								{item.answer}
							</AccordionPanel>
						</AccordionItem>
					))}
				</Accordion>
			</VStack>
		</Container>
	)
}

export default FAQPage