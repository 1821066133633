import React from 'react';
import { createIcon } from '@chakra-ui/react';
import { LuCrown } from 'react-icons/lu';

export const BlueberryIcon = createIcon({
  displayName: 'BlueberryIcon',
  viewBox: '0 0 36 36',
  path: (
    <>
      <path fill="#5C913B" d="M8.089 1s5.858.616 8.54 4.06c3.206 4.117.891 7.962.891 7.962s-4.825-.425-7.03-3.157C7.287 5.896 8.089 1 8.089 1z" />
      <path fill="#77B255" d="M21.901 1s.913 4.617-1.006 7.47c-2.293 3.41-5.676 2.54-5.676 2.54s-.813-3.784.691-6.106C18.096 1.53 21.901 1 21.901 1z" />
      <ellipse fill="#5864B7" cx="23.737" cy="11.536" rx="6.916" ry="6.027" />
      <path fill="#334372" d="M19.34 16.996c0-.851-.124-1.64-.34-2.373a12.608 12.608 0 0 1 3.983-.641c2.263 0 4.355.55 6.073 1.562C27.818 17.147 25.7 18 23.292 18c-1.476 0-2.834-.331-3.955-.949c0-.019.003-.036.003-.055z" />
      <path fill="#334372" d="M21.761 16.558c0 .27-.017.531-.04.789c-2.836-.632-4.901-2.641-4.901-5.811c0-.778.129-1.483.359-2.119c2.732 1.246 4.582 3.739 4.582 7.141z" />
      <ellipse fill="#5864B7" cx="10.892" cy="16.558" rx="8.892" ry="8.035" />
      <path fill="#334372" d="M10.892 24.091c0-5.278 3.75-8.713 8.687-9.406c.132.589.206 1.213.206 1.872c0 4.984-3.968 8.024-8.867 8.034c-.008-.166-.026-.329-.026-.5z" />
      <ellipse transform="rotate(-60 17.507 21.52)" fill="#AA8DD8" cx="17.507" cy="21.519" rx="2.525" ry="2.5" />
      <ellipse fill="#5864B7" cx="22.44" cy="25.095" rx="10.56" ry="9.542" />
      <path fill="#334372" d="M17.043 19.746c.702-1.228 1.699-.59 2.881-1.366c.95-.624 1.079-1.574 2.079-1.574s1.604.832 2.168 1.04c1.327.489 2.119-.228 3.119.772s-.772 1.901-.921 2.673c-.189.982 1.04 1.693.178 2.168c-1.237.683-1.549-.556-3.547-.459c-1.502.073-2.096 1.528-3.403 1.082c-1.339-.456.475-1.752-.327-2.584c-.981-1.018-2.702-.92-2.227-1.752zm-10.871-7.99c.445-1.036 1.065-.439 1.932-1.161c.696-.581.712-1.345 1.504-1.436c.792-.091 1.346.514 1.812.627c1.096.267 1.88-.08 2.541.329c.959.594-.138 1.312-.186 1.937c-.061.795.676 1.511.036 1.966c-.919.654-1.492-.174-3.066.083c-1.183.194-1.271 1.037-2.347.802c-1.102-.24-.151-1.315-.861-1.901c-.869-.716-1.667-.544-1.365-1.246zM24.39 6.679c.831-.296.818.377 1.693.49c.703.091 1.15-.307 1.624.1c.473.406.421 1.045.604 1.373c.429.77 1.046 1.036 1.162 1.632c.17.866-.826.618-1.21.924c-.488.388-.508 1.191-1.108 1.066c-.864-.18-.693-.948-1.678-1.714c-.74-.576-1.27-.177-1.708-.919c-.448-.76.674-.785.632-1.505c-.051-.881-.573-1.247-.011-1.447z" />
      <ellipse fill="#253356" cx="22.667" cy="20.182" rx="3.758" ry="2.387" />
      <ellipse fill="#253356" cx="10.455" cy="11.945" rx="2.818" ry="1.791" />
      <ellipse transform="rotate(-40.137 26.699 9.278)" fill="#253356" cx="26.7" cy="9.278" rx="1.293" ry="2.035" />
    </>
  ),
});

export const CrownIcon = LuCrown

export const BalloonIcon = createIcon({
  displayName: 'BalloonIcon',
  viewBox: '0 0 36 36',
  path: (
    <>
      <path fill="#FCAB40" d="M27.999 36a1 1 0 0 1-.969-.758c-.036-.136-.926-3.436-4.273-4.272C18.054 29.794 18 23.277 18 23a1 1 0 0 1 .998-.999c.554.034 1 .445 1.002.997c0 .053.054 5.234 3.242 6.032c4.53 1.132 5.682 5.54 5.727 5.728a.998.998 0 0 1-.97 1.242z" />
      <path fill="#BB1A34" d="M18 25c-1.1 0-1.598-.805-1.105-1.789l1.211-2.422c.492-.984 1.297-.984 1.789 0l1.211 2.422C21.598 24.195 21.1 25 20 25h-2z" />
      <path fill="#BB1A34" d="M28 9c0 6-7 12-9 12s-9-6-9-12c0-5.799 4.582-9 9-9s9 3.201 9 9z" />
      <path fill="#FCAB40" d="M20 22h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z" />
    </>
  ),
});

export const ExpandIcon = createIcon({
  displayName: 'ExpandIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M7,14H5v5h5v-2H7V14z M5,10h2V7h3V5H5V10z M17,17h-3v2h5v-5h-2V17z M14,5v2h3v3h2V5H14z"
    />
  ),
});

export const HeartIcon = createIcon({
  displayName: 'HeartIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
    />
  ),
});