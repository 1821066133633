export const handlePatreonLink = async (userId) => {
	const clientId = process.env.REACT_APP_PATREON_CLIENT_ID;
	const redirectUri = encodeURIComponent(process.env.REACT_APP_PATREON_REDIRECT_URI);
	const state = encodeURIComponent(JSON.stringify(userId));
	const scope = encodeURIComponent('identity identity.memberships');

	const patreonAuthUrl = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

	window.location.href = patreonAuthUrl;
};

export const getPatreonBurstingTierLink = () => {
	return "https://www.patreon.com/checkout/spherify?rid=24022161";
};

export const getPatreonStuffedTierLink = () => {
	return "https://www.patreon.com/checkout/spherify?rid=23722070";
};