import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Heading, Text, Spinner, useToast } from '@chakra-ui/react';
import { updateSubscription } from './subscription_utils';
import { supabase } from './index';
import { useRollbar } from '@rollbar/react';

function PatreonLinkingPage() {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const toast = useToast();
	const effectRan = useRef(false);
	const rollbar = useRollbar();

	useEffect(() => {
		const handlePatreonRedirect = async () => {
			if (effectRan.current) return;
			effectRan.current = true;

			const searchParams = new URLSearchParams(location.search);
			const code = searchParams.get('code');
			const state = searchParams.get('state');

			console.log({
				searchParams,
				code,
				state
			});

			if (!code) {
				setError('No authorization code received from Patreon');
				setIsLoading(false);
				return;
			}

			try {
				// Call API endpoint to handle the Patreon token exchange and storage
				const response = await fetch('/api/patreon_link', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ code, state }),
				});

				if (!response.ok) {
					const errorData = await response.json();
					rollbar.error("Error linking patreon account", error)
					throw new Error(errorData.error || 'Failed to link Patreon account. Please try again');
				}

				toast({
					title: 'Patreon account linked successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});

				const token = await supabase.auth.getSession();
				await updateSubscription(token.data.session.access_token)

				// Redirect to the settings page after successful linking
				navigate('/account?tab=third-party');
			} catch (error) {
				rollbar.error('Error linking Patreon account:', error);
				setError(error.message);
			} finally {
				setIsLoading(false);
			}
		};

		handlePatreonRedirect();

		// Cleanup function
		return () => {
			effectRan.current = true;
		};
	}, [location, navigate, toast]);

	if (isLoading) {
		return (
			<Box textAlign="center" mt={10}>
				<Spinner size="xl" />
				<Text mt={4}>Linking your Patreon account...</Text>
			</Box>
		);
	}

	if (error) {
		rollbar.error("Error Linking Patreon Account", error)
		return (
			<Box textAlign="center" mt={10}>
				<Heading size="lg" color="red.500">Error</Heading>
				<Text mt={4}>{error}</Text>
			</Box>
		);
	}

	return null;
}

export default PatreonLinkingPage;