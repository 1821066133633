import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, FormControl, FormLabel, Input, Button, useToast } from '@chakra-ui/react';
import { supabase } from './index'; // Adjust this import based on your Supabase client location
import { useNavigate } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';

function ResetPassword() {
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const navigate = useNavigate();
	const rollbar = useRollbar();

	useEffect(() => {
		// Check if the user is authenticated
		const checkSession = async () => {
			const { data: { session } } = await supabase.auth.getSession();
			if (!session) {
				toast({
					title: "Invalid or expired reset link",
					description: "Please request a new password reset link.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				navigate('/');
			}
		};

		checkSession();
	}, [navigate, toast]);

	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (newPassword !== confirmPassword) {
			toast({
				title: "Passwords do not match",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		setLoading(true);
		try {
			const { error } = await supabase.auth.updateUser({ password: newPassword });
			if (error) throw error;

			toast({
				title: "Password updated successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			navigate('/');
		} catch (error) {
			rollbar.error("Error resetting password", error);
			toast({
				title: "Error resetting password",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box maxWidth="400px" margin="auto" mt={8}>
			<VStack spacing={4} align="stretch">
				<Heading as="h1" size="xl" textAlign="center">Reset Your Password</Heading>
				<form onSubmit={handleResetPassword}>
					<VStack spacing={4}>
						<FormControl isRequired>
							<FormLabel>New Password</FormLabel>
							<Input
								type="password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder="Enter your new password"
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Confirm New Password</FormLabel>
							<Input
								type="password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								placeholder="Confirm your new password"
							/>
						</FormControl>
						<Button
							type="submit"
							colorScheme="blue"
							width="100%"
							isLoading={loading}
							loadingText="Resetting Password"
						>
							Reset Password
						</Button>
					</VStack>
				</form>
			</VStack>
		</Box>
	);
}

export default ResetPassword;