import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Menu, MenuButton, MenuList, MenuItem, Button, useDisclosure, HStack, VStack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { supabase } from './index';
import HamburgerMenu from './HamburgerMenu';
import { usePostHog } from 'posthog-js/react'
import { useRollbar } from '@rollbar/react';
import { BURSTING_TIER, STUFFED_TIER, updateSubscription } from "./subscription_utils";
import { isPremium } from "./subscription_utils";
import PremiumModal from './PremiumModal';
import { blueberryColor, premiumCrownColor, tokenColor } from './colors';
import { BlueberryIcon, CrownIcon } from './CustomIcons';

const Header = ({ session, onOpenAuthModal }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const posthog = usePostHog()
  const rollbar = useRollbar()

  const handleOpenPremiumModal = () => {
    onOpen();
  };

  const setIdentifiers = (userId, email, username, tier) => {
    try {
      posthog.identify(
        email,
        {
          userId: userId,
          tier: tier,
          username: username,
          email: email
        }
      );
      rollbar.configure({
        payload: {
          person: {
            id: userId,
            email: email,
            tier: tier,
            username: username,
          }
        }
      });
    } catch (error) {
      console.error('Error setting identifiers:', error);
      rollbar.error('Error setting identifiers', error);
    }
  };

  useEffect(() => {
    let supabaseChannel;

    async function fetchUserInfo(userId) {
      const { data: userData, error: userError } = await supabase
        .from('user_info')
        .select('username, free_tokens, premium_tokens')
        .eq('user_id', userId)
        .single();

      if (userError) {
        rollbar.error('Error fetching user info:', userError);
      } else {
        setUserInfo(userData);
      }

      // Fetch subscription status
      const { data: subscriptionData, error: subscriptionError } = await supabase
        .from('subscriptions')
        .select('tier')
        .eq('user_id', userId)
        .single();

      if (subscriptionError) {
        rollbar.error('Error fetching subscription info:', subscriptionError);
      } else {
        setSubscriptionStatus(subscriptionData);
      }

      if (userData && subscriptionData) {
        const user = session.user
        setIdentifiers(userId, user.email, userData.username, subscriptionData.tier)
      } else {
        rollbar.error('Unable to set identifiers')
      }
    }

    function setupRealtimeSubscription(userId) {
      supabaseChannel = supabase
        .channel('user_updates')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'user_info',
            filter: `user_id=eq.${userId}`
          },
          (payload) => {
            console.log('User info change received!', payload);
            setUserInfo(payload.new);
          }
        )
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'subscriptions',
            filter: `user_id=eq.${userId}`
          },
          (payload) => {
            setSubscriptionStatus(payload.new);
          }
        )
        .subscribe();
    }

    if (session) {
      fetchUserInfo(session.user.id);
      setupRealtimeSubscription(session.user.id);
    } else {
      setUserInfo(null);
      setSubscriptionStatus(null);
      if (supabaseChannel) {
        supabaseChannel.unsubscribe();
      }
    }

    return () => {
      if (supabaseChannel) {
        supabaseChannel.unsubscribe();
      }
    };
  }, [session, posthog, rollbar]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    } else {
      posthog.reset()
      rollbar.configure({
        payload: {}
      });
      navigate('/');
    }
  };

  const JoinPremiumButton = () => (
    <Button
      onClick={handleOpenPremiumModal}
      bg="green.500"
      color="white"
      _hover={{ bg: "green.600" }}
      size="sm"
      px={2}
    >
      <VStack spacing={0}>
        <Text fontSize="xs">Join</Text>
        <Text fontSize="xs">Premium</Text>
      </VStack>
    </Button>
  );

  return (
    <Box position="fixed" top={2} left={2} right={2} zIndex={1000}>
      <Flex justifyContent="space-between" alignItems="center">
        <HStack spacing={2}>
          <HamburgerMenu />
          {session && userInfo && !isPremium(subscriptionStatus?.tier) && (
            <JoinPremiumButton />
          )}
        </HStack>
        <HStack spacing={2}>
          {session && userInfo && (
            <Text
              fontWeight="bold"
              fontSize="sm"
              color="white"
              textShadow="0 0 5px rgba(0,0,0,0.5)"
            >
              {isPremium(subscriptionStatus?.tier) ? userInfo.premium_tokens : userInfo.free_tokens}{' '}
              <Text as="span" color={tokenColor}>tokens</Text>
            </Text>
          )}
          {session && userInfo ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="transparent"
                color="white"
                _hover={{ bg: "rgba(255,255,255,0.1)" }}
                _active={{ bg: "rgba(255,255,255,0.2)" }}
                size="sm"
                px={2}  // Reduce horizontal padding
              >
                <Flex alignItems="center">
                  <Text fontWeight="bold" fontSize="sm" textShadow="0 0 5px rgba(0,0,0,0.5)">
                    {userInfo.username}
                  </Text>
                  {subscriptionStatus?.tier === STUFFED_TIER && (
                    <CrownIcon size={16} color={premiumCrownColor} style={{ marginLeft: '4px', marginRight: '-6px' }} />
                  )}
                  {subscriptionStatus?.tier === BURSTING_TIER && (
                    <BlueberryIcon size={16} color={blueberryColor} style={{ marginLeft: '4px', marginRight: '-6px' }} />
                  )}
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleNavigate('/account')}>Account</MenuItem>
                <MenuItem onClick={() => handleNavigate('/history')}>History</MenuItem>
                <MenuItem onClick={() => handleNavigate('/favorites')}>Favorites</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Button
              onClick={onOpenAuthModal}
              bg="transparent"
              color="white"
              _hover={{ bg: "rgba(255,255,255,0.1)" }}
              _active={{ bg: "rgba(255,255,255,0.2)" }}
              size="sm"
            >
              Sign In
            </Button>
          )}
        </HStack>
      </Flex>

      <PremiumModal isOpen={isOpen} onClose={onClose} userId={session?.user?.id} />
    </Box>
  );
};

export default Header;