import {
	VStack,
	Text,
	Image,
	HStack,
	Box,
	Code,
	Heading,
	Flex,
} from '@chakra-ui/react';
import { BACKSIDE_MODE, FACE_MODE, FULL_BODY_MODE } from './constants';

const GeneralImageGenInfo = () => (
	<VStack spacing={1} align="stretch">
		<VStack spacing={1} align="stretch">
			<Heading size="sm" color="blue.500">How to prompt</Heading>
			<Text>
				In general, when prompting, you should be incredibly detailed. For example, instead of saying "worried expression", you might say "She has an expression of intense worry, her eyes wide and pleading. Her eyes look left, searching for help. Her brow is furrowed, and you can clearly see worry lines creasing her forehead."
			</Text>
		</VStack>
		<VStack spacing={1} align="stretch">
			<Heading size="sm" color="blue.500">Emphasizing Sections Of The Prompt</Heading>
			<Text>
				You can give certain sections of your prompt more effect on the output by wrapping it in paranetheses. Every set of paranetheses you add will increase the weighting by 10% {"("}Note that the paranetheses stack, so if you do 3, the weighting will be 1.1 x 1.1 x 1.1 = 1.33 or 33% rather than 30%{")"}. This is very useful for getting the generator to follow your prompt closely.
			</Text>
			<Text>For example, if you are trying to get the woman to have elf ears and the generator isn't following your instructions, you should probably include parantheses in that part of the prompt:</Text>
			<Code>{"((((The inflated woman has large pointed elf ears))))"}</Code>
		</VStack>
	</VStack>
)

export const modeInfo = {
	[FACE_MODE]: {
		is_premium: false,
		title: "Face Mode",
		content: (
			<VStack spacing={4} align="center" w="full" mt={-4}>
				<Text textAlign="center">This mode is optimized for up-close inflation shots!</Text>
				<Image
					src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/4ee695d1.jpg"
					alt="Face Mode Example - blueberry"
					borderRadius="md"
					w="100%"
					objectFit="cover"
				/>
				<Text textAlign="center" maxW="600px">
					Capturing a few feet from the inflated subject's face, you can easily generate different types of inflations including air and blueberry.
				</Text>
				<Image
					src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/fdaffew3.jpg"
					alt="Face Mode Example - air"
					borderRadius="md"
					w="100%"
					objectFit="cover"
				/>
				<Text textAlign="center">To use this mode, you should start the prompt off with something like:</Text>
				<Code textAlign="center">
					In this image, we see a {"{"}blonde, brunette, etc{"}"} woman in the end stages of a {"{"}blueberry, air, etc{"}"} inflation.
				</Code>
			</VStack>
		)
	},
	[BACKSIDE_MODE]: {
		is_premium: false,
		title: "Backside Mode",
		content: (
			<VStack spacing={4} align="center" w="full" mt={-4}>
				<Text textAlign="center">This mode is optimized for backside inflation shots!</Text>
				<Text textAlign="center" maxW="600px">
					Allowing for both blueberry, air, pumpkin, etc inflations, you can generate an image of the backside of an inflated woman as she's helplessly rolled off.
				</Text>
				<Image
					src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/backside.jpg"
					alt="Backside Mode Example - blueberry juicing room"
					borderRadius="md"
					w="100%"
					objectFit="cover"
				/>
				<Text textAlign="center" maxW="600px">
					This mode also specializes in depicting women stuck in doorways! Give it a try by including a similar sentence in your prompt to the one below:
				</Text>
				<Code textAlign="center">
					The woman is squeezed inside of a huge circular doorway that could fit an elephant, but cannot accommodate for her massive frame.
				</Code>
				<Image
					src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/25456f1b.jpg"
					alt="Backside Mode Example - stuck"
					borderRadius="md"
					w="100%"
					objectFit="cover"
				/>
				<Text textAlign="center">To use this mode, you should start the prompt off with something like:</Text>
				<Code textAlign="center">
					In this image, we see a realistic picture of the backside of a woman who is in the end stage of a {"{"}blueberry, air, etc{"}"} inflation transformation, resulting in her body becoming a massive, {"{"}dark blue skin textured, peach-colored skin textured{"}"} spherical shape.
				</Code>
			</VStack>
		)
	},
	[FULL_BODY_MODE]: {
		is_premium: true,
		title: "Full Body Mode",
		content: (
			<>
				<Text textAlign="center" fontSize="md" color="yellow.300" mb={2} mt={-4}>
					Exclusive to premium users only.
				</Text>
				<VStack spacing={4} align="center" w="full">
					<Text textAlign="center">This mode is optimized for full body inflation shots!</Text>
					<Text textAlign="center" maxW="600px">
						Specialized in capturing the entire body of an inflated woman, this mode can generate full body air and blueberry inflations, along with tearing clothes.
					</Text>
					<Image
						src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/d5ab9d65.jpg"
						alt="Full Body Mode Example - blueberry"
						borderRadius="md"
						w="100%"
						objectFit="cover"
					/>
					<Text textAlign="center" maxW="600px">
						This mode tends to be highly creative, producing a wide range of body shapes and stages of an inflation.
					</Text>
					<Image
						src="https://d1yl8b3k2xxb93.cloudfront.net/homePage/f5d4c5cc.jpg"
						alt="Full Body Mode Example - air"
						borderRadius="md"
						w="100%"
						objectFit="cover"
					/>
					<Text textAlign="center">To use this mode, you should start the prompt off with something like:</Text>
					<Code textAlign="center">
						In this image, we see a {"{"}blonde, brunette, etc{"}"} woman in the end stages of a {"{"}blueberry, air, etc{"}"} inflation. She has rounded out into a massive, completely spherical shape.
					</Code>
				</VStack>
			</>
		)
	}
};