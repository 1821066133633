import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import './index.css'
import { createClient } from '@supabase/supabase-js'
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react'

posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST,
    person_profiles: 'identified_only',
  }
);

const container = document.getElementById('root');
const root = createRoot(container);

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_API_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
)

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </PostHogProvider>
  </React.StrictMode>
);