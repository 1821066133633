
import { FaDiscord } from 'react-icons/fa';
import { FaPatreon } from "react-icons/fa6";

export const MAX_IMG_SIZE = 2048
export const MAX_PROMPT_CHARS = 3000;
export const MAX_SEED_LENGTH = 100;
export const PATREON = "patreon"
export const DEVIANTART = "deviantart"
export const SECONDS_TO_MILLISECONDS = 1000;
export const socialLinks = [
	{ name: 'Discord', url: 'https://discord.gg/cQtSgnFcDk', icon: FaDiscord },
	{ name: 'Patreon', url: 'https://www.patreon.com/spherify', icon: FaPatreon },
];
export const notEnoughTokensError = "Not enough tokens."
export const premiumImageModeTypeForFreeUserError = "The image type you selected is only for premium users. Please join premium to use it."
export const imgToImgWhenNotPremiumError = "Image to image generations are only for premium users. Please join premium to use it."
export const MAX_IMAGE_SIZE_MB = 2;
export const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

export const FACE_MODE = "face"
export const BACKSIDE_MODE = "backside"
export const FULL_BODY_MODE = "full body"

export const imageModeTypes = [
	{ id: FACE_MODE, label: "Face", isPremium: false },
	{ id: BACKSIDE_MODE, label: "Backside", isPremium: false },
	{ id: FULL_BODY_MODE, label: "Full Body", isPremium: true },
];

export const imageModeToInfo = {
	[FACE_MODE]: { isPremium: false },
	[BACKSIDE_MODE]: { isPremium: false },
	[FULL_BODY_MODE]: { isPremium: true },
}

export const PremiumOnlyFeature = "Premium Only Feature"

export const IMAGE_TO_IMAGE_GEN_TYPE = "image to image"
export const TEXT_TO_IMAGE_GEN_TYPE = "text to image"
export const INPAINT_IMAGE_GEN_TYPE = "inpaint"
