import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box } from '@chakra-ui/react';

const MaskEditor = ({
	src,
	cursorSize,
	canvasRef,
	drawMode,
	width,
	height,
	onMaskChange,
	scale = 1,
}) => {
	const imageRef = useRef(null);
	const isDrawing = useRef(false);
	const maskCanvasRef = useRef(null);
	const lastDrawnPoint = useRef(null);
	const [cursorPosition, setCursorPosition] = useState({ x: -1000, y: -1000 });

	// Calculate display dimensions while maintaining aspect ratio
	const displayDimensions = useMemo(() => {
		const containerWidth = 600;
		const containerHeight = 600;
		const imageAspectRatio = width / height;

		let displayWidth, displayHeight;

		if (width > height) {
			displayWidth = Math.min(width, containerWidth);
			displayHeight = displayWidth / imageAspectRatio;

			if (displayHeight > containerHeight) {
				displayHeight = containerHeight;
				displayWidth = displayHeight * imageAspectRatio;
			}
		} else {
			displayHeight = Math.min(height, containerHeight);
			displayWidth = displayHeight * imageAspectRatio;

			if (displayWidth > containerWidth) {
				displayWidth = containerWidth;
				displayHeight = displayWidth / imageAspectRatio;
			}
		}

		return {
			width: displayWidth,
			height: displayHeight,
			scaleX: width / displayWidth,
			scaleY: height / displayHeight
		};
	}, [width, height]);

	useEffect(() => {
		if (canvasRef.current) {
			canvasRef.current.clearMask = () => {
				if (maskCanvasRef.current) {
					const maskCtx = maskCanvasRef.current.getContext('2d');
					maskCtx.clearRect(0, 0, width, height);
					updateCanvas();
				}
			};
		}
	}, [width, height]);

	useEffect(() => {
		if (!canvasRef.current || !src) return;

		if (!maskCanvasRef.current) {
			maskCanvasRef.current = document.createElement('canvas');
		}

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d', { willReadFrequently: true });
		const maskCanvas = maskCanvasRef.current;
		const maskCtx = maskCanvas.getContext('2d', { willReadFrequently: true });

		// Set actual image dimensions for both canvases
		canvas.width = width;
		canvas.height = height;
		maskCanvas.width = width;
		maskCanvas.height = height;

		// Set display dimensions via CSS
		canvas.style.width = `${displayDimensions.width}px`;
		canvas.style.height = `${displayDimensions.height}px`;

		ctx.clearRect(0, 0, width, height);
		maskCtx.clearRect(0, 0, width, height);

		const img = new Image();
		img.onload = () => {
			imageRef.current = img;
			updateCanvas();
		};
		img.src = src;
	}, [src, width, height, displayDimensions]);

	const updateCanvas = () => {
		if (!canvasRef.current || !maskCanvasRef.current || !imageRef.current) return;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		requestAnimationFrame(() => {
			ctx.clearRect(0, 0, width, height);

			// Draw original image
			ctx.globalCompositeOperation = 'source-over';
			ctx.drawImage(imageRef.current, 0, 0, width, height);

			// Draw mask
			ctx.globalCompositeOperation = 'color';
			ctx.drawImage(maskCanvasRef.current, 0, 0);

			// Draw cursor if not drawing
			if (!isDrawing.current) {
				ctx.globalCompositeOperation = 'source-over';
				ctx.beginPath();
				ctx.arc(
					cursorPosition.x,
					cursorPosition.y,
					cursorSize * displayDimensions.scaleX,
					0,
					Math.PI * 2
				);
				ctx.strokeStyle = 'white';
				ctx.lineWidth = 2;
				ctx.stroke();
				ctx.strokeStyle = 'black';
				ctx.lineWidth = 1;
				ctx.stroke();
			}
		});
	};

	const getScaledCoordinates = (e) => {
		if (!canvasRef.current) return { x: 0, y: 0 };

		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();

		// Get mouse position relative to viewport
		const mouseX = e.clientX;
		const mouseY = e.clientY;

		// Get canvas position and dimensions after transform
		const transformedRect = canvas.getBoundingClientRect();

		// Calculate relative position within the transformed canvas
		const relativeX = (mouseX - transformedRect.left) / scale;
		const relativeY = (mouseY - transformedRect.top) / scale;

		// Convert to actual image coordinates
		const x = (relativeX / displayDimensions.width) * width;
		const y = (relativeY / displayDimensions.height) * height;

		return { x, y };
	};

	const interpolatePoints = (x1, y1, x2, y2) => {
		const points = [];
		const dx = x2 - x1;
		const dy = y2 - y1;
		const distance = Math.sqrt(dx * dx + dy * dy);
		const steps = Math.max(Math.floor(distance / (cursorSize / 2)), 1);

		for (let i = 0; i <= steps; i++) {
			points.push({
				x: x1 + (dx * i) / steps,
				y: y1 + (dy * i) / steps
			});
		}
		return points;
	};

	const draw = (e) => {
		if (!canvasRef.current || !maskCanvasRef.current) return;

		const { x, y } = getScaledCoordinates(e);
		setCursorPosition({ x, y });

		if (!isDrawing.current) {
			updateCanvas();
			return;
		}

		// Draw on the mask canvas
		const maskCtx = maskCanvasRef.current.getContext('2d');

		if (drawMode === 'draw') {
			maskCtx.globalCompositeOperation = 'source-over';
			maskCtx.fillStyle = '#eb5773';
		} else {
			maskCtx.globalCompositeOperation = 'destination-out';
		}

		// Interpolate points for smooth drawing
		if (lastDrawnPoint.current) {
			const points = interpolatePoints(
				lastDrawnPoint.current.x,
				lastDrawnPoint.current.y,
				x,
				y
			);

			points.forEach(point => {
				maskCtx.beginPath();
				maskCtx.arc(point.x, point.y, cursorSize * displayDimensions.scaleX, 0, Math.PI * 2);
				maskCtx.fill();
			});
		} else {
			maskCtx.beginPath();
			maskCtx.arc(x, y, cursorSize * displayDimensions.scaleX, 0, Math.PI * 2);
			maskCtx.fill();
		}

		lastDrawnPoint.current = { x, y };
		updateCanvas();
		notifyMaskChange();
	};

	const startDrawing = (e) => {
		isDrawing.current = true;
		lastDrawnPoint.current = null;
		draw(e);
	};

	const stopDrawing = () => {
		isDrawing.current = false;
		lastDrawnPoint.current = null;
		updateCanvas();
		notifyMaskChange();
	};

	const handleMouseLeave = () => {
		setCursorPosition({ x: -1000, y: -1000 });
		stopDrawing();
		updateCanvas();
	};

	const notifyMaskChange = () => {
		if (onMaskChange) {
			const maskData = getMaskData();
			onMaskChange(maskData);
		}
	};

	const getTouchCoordinates = (e) => {
		if (!canvasRef.current) return { x: 0, y: 0 };

		const canvas = canvasRef.current;
		const touch = e.touches[0];
		const transformedRect = canvas.getBoundingClientRect();

		// Calculate relative position within the transformed canvas
		const relativeX = (touch.clientX - transformedRect.left) / scale;
		const relativeY = (touch.clientY - transformedRect.top) / scale;

		// Convert to actual image coordinates
		const x = (relativeX / displayDimensions.width) * width;
		const y = (relativeY / displayDimensions.height) * height;

		return { x, y };
	};

	const drawTouch = (e) => {
		if (!canvasRef.current || !maskCanvasRef.current) return;

		const { x, y } = getTouchCoordinates(e);
		setCursorPosition({ x, y });

		if (!isDrawing.current) {
			updateCanvas();
			return;
		}

		// Draw on the mask canvas
		const maskCtx = maskCanvasRef.current.getContext('2d');

		if (drawMode === 'draw') {
			maskCtx.globalCompositeOperation = 'source-over';
			maskCtx.fillStyle = '#eb5773';
		} else {
			maskCtx.globalCompositeOperation = 'destination-out';
		}

		// Interpolate points for smooth drawing
		if (lastDrawnPoint.current) {
			const points = interpolatePoints(
				lastDrawnPoint.current.x,
				lastDrawnPoint.current.y,
				x,
				y
			);

			points.forEach(point => {
				maskCtx.beginPath();
				maskCtx.arc(point.x, point.y, cursorSize * displayDimensions.scaleX, 0, Math.PI * 2);
				maskCtx.fill();
			});
		} else {
			maskCtx.beginPath();
			maskCtx.arc(x, y, cursorSize * displayDimensions.scaleX, 0, Math.PI * 2);
			maskCtx.fill();
		}

		lastDrawnPoint.current = { x, y };
		updateCanvas();
		notifyMaskChange();
	};

	const startDrawingTouch = (e) => {
		e.preventDefault(); // Prevent scrolling while drawing
		isDrawing.current = true;
		lastDrawnPoint.current = null;
		drawTouch(e);
	};

	const stopDrawingTouch = () => {
		isDrawing.current = false;
		lastDrawnPoint.current = null;
		updateCanvas();
		notifyMaskChange();
	};

	const getMaskData = () => {
		if (!maskCanvasRef.current) return null;

		const tempCanvas = document.createElement('canvas');
		tempCanvas.width = width;
		tempCanvas.height = height;
		const tempCtx = tempCanvas.getContext('2d');

		tempCtx.fillStyle = 'black';
		tempCtx.fillRect(0, 0, width, height);
		tempCtx.globalCompositeOperation = 'source-in';
		tempCtx.drawImage(maskCanvasRef.current, 0, 0);

		return tempCanvas.toDataURL('image/png');
	};

	return (
		<Box position="relative">
			<canvas
				ref={canvasRef}
				style={{
					width: `${displayDimensions.width}px`,
					height: `${displayDimensions.height}px`,
					cursor: 'none',
					touchAction: 'none', // Prevent default touch behaviors
				}}
				onMouseDown={startDrawing}
				onMouseMove={draw}
				onMouseUp={stopDrawing}
				onMouseLeave={handleMouseLeave}
				onTouchStart={startDrawingTouch}
				onTouchMove={drawTouch}
				onTouchEnd={stopDrawingTouch}
				onTouchCancel={stopDrawingTouch}
			/>
		</Box>
	);
};

export default MaskEditor;