const MEDIUM_TIME_PER_MEGAPIXEL_SEC = 2.24
const BASE_TIME_SEC = 3

const HIGH_TIME_PER_MEGAPIXEL = MEDIUM_TIME_PER_MEGAPIXEL_SEC * 5

const CENTS_PER_SEC = 0.084
const TOKEN_CONVERSION = 100

export const MEDIUM_MODEL = "medium"
export const HIGH_MODEL = "high"

const MODEL_TO_TIME = {
	medium: MEDIUM_TIME_PER_MEGAPIXEL_SEC,
	high: HIGH_TIME_PER_MEGAPIXEL
}

export function timeToGen(width, height, modelType, numImages) {
	const megapixels = width * height / 1000000;
	const timePerMegapixel = MODEL_TO_TIME[modelType]

	return BASE_TIME_SEC +
		timePerMegapixel * megapixels * numImages;
}

export function costToGen(width, height, modelType, numImages) {
	const genTime = timeToGen(width, height, modelType, numImages)

	return numImages
}