import React, { useState, useEffect, useCallback } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Image,
	Box,
	Text,
	Flex,
	VStack,
	IconButton,
	Tooltip,
	useToast,
	HStack,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CopyIcon, DownloadIcon, createIcon } from '@chakra-ui/icons';
import { useRollbar } from '@rollbar/react';
import { extractFilename, formatUTCToLocal } from './imageUtils';
import { supabase } from './index';
import { ExpandIcon, HeartIcon } from './CustomIcons';
import { TEXT_TO_IMAGE_GEN_TYPE } from './constants';

const ImageModal = ({ isOpen, onClose, images, currentIndex, onIndexChange, onFavoriteToggle }) => {
	const [localIndex, setLocalIndex] = useState(0);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const rollbar = useRollbar();
	const toast = useToast();

	useEffect(() => {
		if (images.length > 0) {
			setLocalIndex(Math.min(currentIndex, images.length - 1));
		}
	}, [currentIndex, images.length, isOpen]);

	const handlePrevious = useCallback(() => {
		const newIndex = localIndex > 0 ? localIndex - 1 : images.length - 1;
		setLocalIndex(newIndex);
		onIndexChange(newIndex);
	}, [localIndex, images.length, onIndexChange]);

	const handleNext = useCallback(() => {
		const newIndex = localIndex < images.length - 1 ? localIndex + 1 : 0;
		setLocalIndex(newIndex);
		onIndexChange(newIndex);
	}, [localIndex, images.length, onIndexChange]);

	// Add keyboard navigation
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (!isOpen) return;

			switch (event.key) {
				case 'ArrowLeft':
					event.preventDefault();
					handlePrevious();
					break;
				case 'ArrowRight':
					event.preventDefault();
					handleNext();
					break;
				case 'Escape':
					event.preventDefault();
					closeModal();
					break;
				default:
					break;
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		// Cleanup
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, handlePrevious, handleNext]); // Include all dependencies

	const handleDownload = async () => {
		try {
			const response = await fetch(images[localIndex].image_url);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `${extractFilename(images[localIndex].image_url)}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			rollbar.error('Download failed:', error);
		}
	};

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	const closeModal = () => {
		setIsFullScreen(false);
		onClose();
	};

	const handleFavorite = useCallback(async () => {
		try {
			const currentImage = images[localIndex];
			const token = await supabase.auth.getSession();
			const { data: userData, error: userError } = await supabase.auth.getUser()
			if (userError) {
				throw userError
			}
			const user = userData.user;

			if (currentImage.isFavorited) {
				if (currentImage.favoritedId === null) {
					throw new Error("Error removing from favorites, no favoritedId")
				}

				// Remove from favorites
				const { error } = await supabase
					.from('fav_images')
					.delete()
					.eq('user_id', user.id)
					.eq('id', currentImage.favoritedId);

				if (error) throw error;

				onFavoriteToggle(localIndex, false);

				toast({
					title: "Removed from favorites",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			} else {
				// Add to favorites
				const response = await fetch('/api/favorite_image', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token.data.session.access_token}`
					},
					body: JSON.stringify({
						image_gens_id: currentImage.image_gens_id,
						image_gens_index: currentImage.image_gens_index,
						prompt: currentImage.prompt,
						seed: currentImage.seed,
						width: currentImage.width,
						height: currentImage.height,
						selected_lora: currentImage.selected_lora,
						selected_model: currentImage.selected_model,
						image_url: currentImage.image_url,
						created_at: currentImage.created_at,
					}),
				});

				if (!response.ok) throw new Error('Failed to add to favorites');

				const resp = await response.json();
				onFavoriteToggle(localIndex, true, resp.favoritedImageId);

				toast({
					title: "Added to favorites",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (error) {
			rollbar.error('Failed to toggle favorite:', error);
			toast({
				title: "Failed to update favorites",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	}, [images, localIndex, onFavoriteToggle, rollbar, toast]);

	const handleCopyPrompt = async () => {
		try {
			await navigator.clipboard.writeText(currentImage.prompt);
			toast({
				title: "Prompt copied to clipboard",
				status: "success",
				duration: 2000,
				isClosable: true,
			});
		} catch (error) {
			console.log(error)
			rollbar.error('Failed to copy prompt:', error);
			toast({
				title: "Failed to copy prompt",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};


	if (images.length <= 0) {
		return null;
	}

	const currentImage = images[localIndex];

	return (
		<Modal allowPinchZoom={true} isOpen={isOpen} onClose={closeModal} size={isFullScreen ? "full" : "xl"}>
			<ModalOverlay backdropFilter="blur(10px)" />
			<ModalContent
				margin="auto"
				width={"100%"}
				height={"100%"}
				maxWidth="unset"
				bg="transparent"
				boxShadow="none"
			>
				{!isFullScreen && <ModalCloseButton zIndex="popover" />}
				<ModalBody p={0} display="flex" flexDirection={isFullScreen ? "column" : "row"} overflow="hidden">
					{currentImage && (
						<Flex
							flex={isFullScreen ? 1 : 7}
							position="relative"
							alignItems="center"
							justifyContent="center"
							onClick={closeModal}
						>
							<Image
								src={currentImage.image_url}
								alt={`Generated Image ${localIndex + 1}`}
								objectFit="contain"
								maxWidth="100%"
								maxHeight="100%"
								width="auto"
								height="auto"
								onClick={(e) => e.stopPropagation()}
							/>
							<>
								<IconButton
									icon={<ChevronLeftIcon />}
									onClick={(e) => { e.stopPropagation(); handlePrevious(); }}
									position="absolute"
									left={2}
									top="50%"
									transform="translateY(-50%)"
									aria-label="Previous image"
								/>
								<IconButton
									icon={<ChevronRightIcon />}
									onClick={(e) => { e.stopPropagation(); handleNext(); }}
									position="absolute"
									right={2}
									top="50%"
									transform="translateY(-50%)"
									aria-label="Next image"
								/>
							</>
							<IconButton
								icon={<ExpandIcon />}
								onClick={(e) => { e.stopPropagation(); toggleFullScreen(); }}
								position="absolute"
								right={2}
								bottom={2}
								aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
							/>
						</Flex>
					)}
					{!isFullScreen && currentImage && (
						<Box
							flex="3"
							p={6}
							overflowY="auto"
							bg="black"
							onClick={(e) => e.stopPropagation()}
						>
							<VStack align="stretch" spacing={2}>
								<Text fontSize="2xl" fontWeight="bold" color="white">
									Image Details
								</Text>
								<Tooltip label={"Download"} hasArrow>
									<IconButton
										icon={<DownloadIcon />}
										onClick={handleDownload}
										aria-label={"Download"}
									/>
								</Tooltip>
								<Tooltip label={currentImage.isFavorited ? "Remove from favorites" : "Add to favorites"} hasArrow>
									<IconButton
										icon={<HeartIcon />}
										onClick={handleFavorite}
										aria-label={currentImage.isFavorited ? "Remove from favorites" : "Add to favorites"}
										colorScheme={currentImage.isFavorited ? "red" : "gray"}
									/>
								</Tooltip>
								<Text color="white"><strong>Created:</strong> {formatUTCToLocal(currentImage.created_at)}</Text>
								<Text color="white"><strong>Size:</strong> {currentImage.width} x {currentImage.height}</Text>
								<Text color="white"><strong>Generation Type:</strong> {currentImage.gen_type ? currentImage.gen_type : TEXT_TO_IMAGE_GEN_TYPE}</Text>
								<Text color="white"><strong>Image Mode:</strong> {currentImage.selected_lora}</Text>
								<Text color="white"><strong>Seed:</strong> {currentImage.seed}</Text>
								<HStack>
									<Text color="white" fontWeight="bold">Prompt:</Text>
									<Tooltip label="Copy prompt" hasArrow>
										<IconButton
											icon={<CopyIcon />}
											onClick={handleCopyPrompt}
											aria-label="Copy prompt"
											size="sm"
										/>
									</Tooltip>
								</HStack>
								<Text color="white">{currentImage.prompt}</Text>
							</VStack>
						</Box>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ImageModal;