import React from 'react';
import {
	FormControl,
	FormLabel,
	HStack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Box,
	Text,
	Slider,
	Tooltip,
	IconButton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const DenoiseStrengthControl = ({
	denoiseStrength,
	setDenoiseStrength,
	denoiseInputValue,
	setDenoiseInputValue,
}) => {
	return (
		<FormControl>
			<FormLabel>
				<HStack spacing={1} alignItems="center">
					<Text>Denoise Strength</Text>
					<Tooltip label="The denoise strength is used to determine how close the generated image should be to the inputted image. A lower denoise will make the output more similar to the input." hasArrow>
						<IconButton
							icon={<InfoIcon />}
							size="xs"
							variant="ghost"
							aria-label="Denoise strength information"
						/>
					</Tooltip>
				</HStack>
			</FormLabel>
			<HStack spacing={4}>
				<NumberInput
					maxW="120px"
					mr="2rem"
					value={denoiseInputValue}
					onChange={(valueString) => {
						setDenoiseInputValue(valueString);
					}}
					onBlur={() => {
						const value = Number(denoiseInputValue);
						if (!isNaN(value)) {
							const clampedValue = Math.min(Math.max(value, 0), 1);
							const formattedValue = clampedValue.toFixed(2);
							setDenoiseStrength(Number(formattedValue));
							setDenoiseInputValue(formattedValue);
						} else {
							setDenoiseInputValue(denoiseStrength.toFixed(2));
						}
					}}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper
							onClick={() => {
								const newValue = Math.min(denoiseStrength + 0.01, 1);
								setDenoiseStrength(newValue);
							}}
						/>
						<NumberDecrementStepper
							onClick={() => {
								const newValue = Math.max(denoiseStrength - 0.01, 0);
								setDenoiseStrength(newValue);
							}}
						/>
					</NumberInputStepper>
				</NumberInput>
				<Box position="relative" flex="1" pt={6}>
					<Slider
						value={denoiseStrength}
						onChange={setDenoiseStrength}
						min={0}
						max={1}
						step={0.01}
					>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb />
					</Slider>
					<Text position="absolute" left={0} top={0} fontSize="sm" color="gray.500">
						Less different
					</Text>
					<Text position="absolute" right={0} top={0} fontSize="sm" color="gray.500">
						More different
					</Text>
				</Box>
			</HStack>
		</FormControl>
	);
};

export default DenoiseStrengthControl;