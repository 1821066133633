import React from 'react';
import { Box, VStack, Heading, Text, UnorderedList, ListItem, Container } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

const TermsOfServicePage = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="2xl" textAlign="center">Terms of Service</Heading>
        <Text>Last updated: September 20, 2024</Text>
        
        <Text>
          Welcome to spherify.ai! We provide a cloud-based platform ("Platform") where you can create AI-generated images using our generative AI technology.
        </Text>
        
        <Text>
          In these Terms, "you" or "your" refers to both you and any entity you are authorized to represent. "We," "us," or "our" refers to spherify.ai.
        </Text>
        
        <Text>
          These Terms form our contract with you and set out our obligations as a service provider and your obligations as a customer. You cannot use our Services unless you agree to these Terms.
        </Text>

        <Section title="1. Our Services">
          <Text>1.1 We provide the following services to you:</Text>
          <UnorderedList pl={5}>
            <ListItem>access to our Platform for AI image generation;</ListItem>
            <ListItem>access to our troubleshooting support (Support Services).</ListItem>
          </UnorderedList>
          <Text>1.2 You may request Support Services by contacting us through our Platform.</Text>
        </Section>

        <Section title="2. Account Creation and Use">
          <Text>2.1 You must create an Account to access and use our Services.</Text>
          <Text>2.2 You agree to:</Text>
          <UnorderedList pl={5}>
            <ListItem>provide accurate and up-to-date information;</ListItem>
            <ListItem>keep your login credentials secure and confidential;</ListItem>
            <ListItem>notify us of any unauthorized access to your Account.</ListItem>
          </UnorderedList>
          <Text>2.3 We may close and permanently delete inactive Accounts (12 months or more of inactivity).</Text>
        </Section>

        <Section title="3. User Conduct and Content">
          <Text>3.1 You are solely responsible for all content you generate, upload, or otherwise make available through our Platform ("User Content").</Text>
          <Text>3.2 You agree to comply with our <ChakraLink as={RouterLink} to="/contentpolicy">Content Policy</ChakraLink>, which is incorporated into these Terms by reference. The Content Policy includes our Code of Conduct and content moderation practices.</Text>
          <Text>3.3 You agree not to use our Platform to:</Text>
          <UnorderedList pl={5}>
            <ListItem>violate any applicable laws or regulations;</ListItem>
            <ListItem>infringe upon the rights of others, including intellectual property rights;</ListItem>
            <ListItem>generate or distribute harmful, offensive, or inappropriate content;</ListItem>
            <ListItem>impersonate any person or entity;</ListItem>
            <ListItem>interfere with or disrupt our Services or servers;</ListItem>
            <ListItem>attempt to gain unauthorized access to our Services or systems;</ListItem>
            <ListItem>engage in any activity prohibited by our <ChakraLink as={RouterLink} to="/contentpolicy" color="blue.500">Content Policy</ChakraLink>.</ListItem>
          </UnorderedList>
          <Text>3.4 We reserve the right to remove any User Content that violates these Terms or our Content Policy, or that we deem inappropriate, without prior notice.</Text>
        </Section>

		<Section title="4. Content Policy">
          <Text>4.1 Our <ChakraLink as={RouterLink} to="/contentpolicy" color="blue.500">Content Policy</ChakraLink>, is incorporated into these Terms by reference.</Text>
          <Text>4.2 The Content Policy includes our Code of Conduct, which outlines prohibited activities and content, as well as our content moderation practices.</Text>
          <Text>4.3 Violation of the Content Policy may result in content removal, service suspension, or account termination, as outlined in the policy.</Text>
          <Text>4.4 We reserve the right to modify the Content Policy at any time. Continued use of our Services after changes to the Content Policy constitutes acceptance of the updated policy.</Text>
        </Section>

        <Section title="5. Intellectual Property">
          <Text>5.1 We own all intellectual property rights in our Platform and Services.</Text>
          <Text>5.2 You retain ownership of your User Content. However, by using our Platform, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your User Content solely for the purpose of providing and improving our Services.</Text>
        </Section>

        <Section title="6. Disclaimer and Limitation of Liability">
          <Text>6.1 Our Platform utilizes AI technology to generate images. You acknowledge and agree that:</Text>
          <UnorderedList pl={5}>
            <ListItem>AI-generated content may be imperfect, inaccurate, or inappropriate;</ListItem>
            <ListItem>We do not guarantee the accuracy, quality, or appropriateness of AI-generated images;</ListItem>
            <ListItem>You are solely responsible for reviewing and verifying any AI-generated content before use.</ListItem>
          </UnorderedList>
          <Text>6.2 To the maximum extent permitted by law, we disclaim all warranties, express or implied, regarding our Services and Platform.</Text>
          <Text>6.3 We shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from your use of our Services or any User Content.</Text>
          <Text>6.4 Our total liability for any claims arising from or related to these Terms or our Services shall not exceed the amount you paid us (if any) for the Services in the past 12 months.</Text>
        </Section>

        <Section title="7. Indemnification">
          <Text>7.1 You agree to indemnify, defend, and hold harmless spherify.ai and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of our Services, your User Content, or your violation of these Terms.</Text>
        </Section>

        <Section title="8. Termination">
          <Text>8.1 We may suspend or terminate your access to our Services at any time, without prior notice, for any reason, including if we believe you have violated these Terms.</Text>
          <Text>8.2 Upon termination, your right to use our Services will immediately cease, and we may delete your Account and any associated data.</Text>
        </Section>

        <Section title="9. Changes to Terms">
          <Text>9.1 We may modify these Terms at any time. We will notify you of any material changes by posting the new Terms on our Platform or via email.</Text>
          <Text>9.2 Your continued use of our Services after any changes constitutes your acceptance of the new Terms.</Text>
        </Section>

        <Section title="10. Governing Law and Jurisdiction">
          <Text>10.1 These Terms are governed by the laws of Canada, without regard to its conflict of law provisions.</Text>
          <Text>10.2 Any dispute arising from or relating to these Terms or our Services shall be resolved exclusively in the courts of Canada.</Text>
        </Section>

        <Section title="11. Contact Information">
          <Text>For questions about these Terms or our Services, please contact us at: spherify@spherify.ai</Text>
        </Section>

        <Text fontWeight="bold">
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </Text>
      </VStack>
    </Container>
  );
};

const Section = ({ title, children }) => (
  <Box>
    <Heading as="h2" size="lg" mb={2}>{title}</Heading>
    <VStack align="stretch" spacing={2}>
      {children}
    </VStack>
  </Box>
);

export default TermsOfServicePage;