import { supabase } from './index';

export async function flattenImagesRow(imagesRow) {
	const flattenedImages = []
	const isFavorited = imagesRow.image_urls.map(item => false);
	const favoritedId = imagesRow.image_urls.map(item => null);

	try {
		const { data: userData, error: userError } = await supabase.auth.getUser()
		if (userError) {
			throw userError
		}

		const user = userData.user;
		// check if any images in the batch are favorited
		const { data, error } = await supabase
			.from('fav_images')
			.select()
			.eq('user_id', user.id)
			.eq('image_gens_id', imagesRow.id)

		if (error) {
			throw error
		}
		if (data.length > 0) {
			data.forEach(favRow => {
				isFavorited[favRow.image_gens_index] = true
				favoritedId[favRow.image_gens_index] = favRow.id
			})
		}
	} catch (error) {
		console.log("Error setting favs data", error)
	}

	for (let i = 0; i < imagesRow.image_urls.length; i++) {
		flattenedImages.push({
			...imagesRow,
			image_url: imagesRow.image_urls[i],
			id: `${imagesRow.id}_${imagesRow.image_urls[i]}`,
			image_gens_id: imagesRow.id,
			image_gens_index: i,
			isFavorited: isFavorited[i],
			favoritedId: favoritedId[i]
		})
	}

	return flattenedImages
}

export function convertToCloudFrontURL(s3Url) {
	const regex = /^https:\/\/spherify-image-gen\.s3\.us-west-2\.amazonaws\.com\/(.+?)\?/;
	const match = s3Url.match(regex);
	if (match && match[1]) {
		return `https://d1yl8b3k2xxb93.cloudfront.net/${match[1]}`;
	}
	return s3Url; // Return original URL if it doesn't match the expected format
};

export const extractFilename = (url) => {
	// Split the URL by '/' and get the last segment before the query parameters
	const urlParts = url.split('/');
	const filenameWithParams = urlParts[urlParts.length - 1].split('?')[0];

	// Use regex to match the filename pattern (assuming it always ends with .jpg)
	const match = filenameWithParams.match(/[a-f0-9]+\.jpg$/i);

	return match ? match[0] : 'image.jpg'; // Default to 'image.jpg' if no match is found
};

export function formatUTCToLocal(utcTimestamp) {
	const date = new Date(utcTimestamp);

	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
	};

	const formatter = new Intl.DateTimeFormat('en-US', options);
	const formattedDate = formatter.format(date);

	// Get the time zone abbreviation
	const timeZoneAbbr = new Intl.DateTimeFormat('en-US', {
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		timeZoneName: 'short'
	}).formatToParts(date).find(part => part.type === 'timeZoneName').value;

	return formattedDate.replace(',', '') + ' ' + timeZoneAbbr;
}