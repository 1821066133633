import React, { useState, useCallback, useEffect } from 'react';
import { Box, useDisclosure, Center, Spinner, Icon } from '@chakra-ui/react';
import { Gallery } from 'react-grid-gallery';
import ImageModal from './ImageModal';
import { useInView } from 'react-intersection-observer';
import { HeartIcon } from './CustomIcons';

const ImageGallery = ({ images: initialImages, fetchMoreImages, hasMoreImages, isFetchingNextPage }) => {
  const [images, setImages] = useState(initialImages);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setImages(initialImages);
  }, [initialImages]);

  const handleImageClick = useCallback((index) => {
    setSelectedImageIndex(index);
    onOpen();
  }, [onOpen]);

  const handleCloseModal = useCallback(() => {
    setSelectedImageIndex(null);
    onClose();
  }, [onClose]);

  const handleModalIndexChange = useCallback((newIndex) => {
    setSelectedImageIndex(newIndex);
  }, []);

  const handleFavoriteToggle = useCallback((index, isFavorited, favoritedId = null) => {
    setImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index] = { ...newImages[index], isFavorited, favoritedId };
      return newImages;
    });
  }, []);

  useEffect(() => {
    if (inView && hasMoreImages && !isFetchingNextPage) {
      fetchMoreImages();
    }
  }, [inView, hasMoreImages, isFetchingNextPage, fetchMoreImages]);

  const galleryImages = images.map((image, index) => ({
    src: image.image_url,
    width: image.width,
    height: image.height,
    alt: `Generated Image ${index + 1}`,
    customOverlay: (
      <Box
        position="absolute"
        bottom="8px"
        right="8px"
        backgroundColor="rgba(0, 0, 0, 0.3)"
        borderRadius="full"
        p={1}
      >
        <Icon
          as={HeartIcon}
          boxSize={6}
          color={image.isFavorited ? "red.500" : "gray.300"}
        />
      </Box>
    ),
  }));

  return (
    <Box>
      <Gallery
        images={galleryImages}
        onClick={handleImageClick}
        enableImageSelection={false}
        margin={4}
        rowHeight={400}
      />
      <Box ref={ref} h="20px" />
      {isFetchingNextPage && (
        <Center py={8}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}
      {selectedImageIndex !== null && (
        <ImageModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          images={images}
          currentIndex={selectedImageIndex}
          onIndexChange={handleModalIndexChange}
          onFavoriteToggle={handleFavoriteToggle}
        />
      )}
    </Box>
  );
};

export default ImageGallery;