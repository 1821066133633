import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem, VStack } from '@chakra-ui/react';

const ContentPolicyPage = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding={8}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="2xl" textAlign="center">
          Code of Conduct
        </Heading>

        <Text fontSize="xl" fontWeight="bold" >
          By using spherify.ai's Image Generator, you agree:
        </Text>

        <UnorderedList spacing={1} paddingLeft={6}>
          <ListItem>Not to engage in activity that is harmful to you, or others. Do not attempt to create or share content that could be used to harass, bully, abuse, threaten, or intimidate other individuals, or otherwise cause harm to individuals, organizations, or society.</ListItem>
          <ListItem>Not to engage in activity that is harmful to spherify.ai, including bot/scraping behaviors, technical attacks, excess usage, prompt-based manipulation, and other off-platform abuses.</ListItem>
          <ListItem>Not to engage in activity that violates the privacy of others. Do not attempt to create or share content that could violate the privacy of others, including disclosure of private information (sometimes known as "doxing"). Do not attempt to use spherify.ai for facial identification, or identification verification purposes. Do not input photographs or video/audio recordings of others taken without their consent for the processing of an individual's biometric identifiers or biometric information.</ListItem>
          <ListItem>Not to engage in activity that is fraudulent, false, or misleading. Do not attempt to create or share content that could mislead or deceive others, including for example creation of disinformation, content enabling fraud, or deceptive impersonation.</ListItem>
          <ListItem>Not to create or share images of real people without their explicit consent. This includes but is not limited to public figures, celebrities, or any identifiable individuals. Do not attempt to use spherify.ai to create realistic depictions or manipulations of existing people without their permission.</ListItem>
          <ListItem>Not to infringe on the rights of others. Do not attempt to use spherify.ai to infringe on others' legal rights, including intellectual property rights.</ListItem>
          <ListItem>Not to use the service to create or share inappropriate content or material. Spherify.ai does not permit the use of Image Generator to create or share violence or gore, hateful content, terrorism and violent extremist content, glorification of violence, child sexual exploitation or abuse material, or content that is otherwise disturbing or offensive.</ListItem>
          <ListItem>Not to do anything illegal. Your use of spherify.ai must comply with applicable laws.</ListItem>
        </UnorderedList>

        <Heading as="h2" size="xl" textAlign="center">
          Content and Moderation
        </Heading>

        <p>
          Spherify.ai may block prompts that violate the Code of Conduct, or that are likely to lead to the creation of material that violates the Code of Conduct. Prompts or Creations that violate the Code of Conduct may be removed. Abuse of the image generator, such as repeated attempts to produce prohibited content or other violations of the Code of Conduct, may result in service or account suspension. Users can report problematic or illegal content via the Feedback button.
        </p>
      </VStack>
    </Box>
  );
};

export default ContentPolicyPage;