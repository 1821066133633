import React, { useState, useEffect } from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import { useInfiniteQuery } from 'react-query';
import ImageGallery from './ImageGallery';
import { supabase } from './index';
import { convertToCloudFrontURL, flattenImagesRow } from './imageUtils';

const fetchImages = async ({ pageParam = 0, userId }) => {
	const pageSize = 10;
	const { data, error } = await supabase
		.from('image_gens')
		.select()
		.eq('user_id', userId)
		.not('image_urls', 'is', null)
		.order('created_at', { ascending: false })
		.range(pageParam * pageSize, (pageParam + 1) * pageSize - 1);

	if (error) {
		throw new Error('Error fetching images: ' + error.message);
	}

	// Convert S3 URLs to Cloudfront URLs
	const convertedData = data.map(item => ({
		...item,
		image_urls: item.image_urls.map(convertToCloudFrontURL)
	}));

	// Use Promise.all to wait for all flattenImagesRow calls to complete
	const flattenedData = await Promise.all(convertedData.map(flattenImagesRow));
	return flattenedData.flat();
};

const HistoryPage = () => {
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		const fetchUser = async () => {
			const { data: { user } } = await supabase.auth.getUser();
			if (user) {
				setUserId(user.id);
			}
		};

		fetchUser();
	}, []);

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
	} = useInfiniteQuery(
		['images', userId],
		({ pageParam }) => fetchImages({ pageParam, userId }),
		{
			getNextPageParam: (lastPage, pages) => {
				return lastPage.length === 0 ? undefined : pages.length;
			},
			enabled: !!userId, // Only start the query when we have a userId
		}
	);

	const allImages = data ? data.pages.flat() : [];

	if (!userId) {
		return (
			<Box width="100%" px={4}>
				<Center>
					<Heading as="h2" size="lg">
						Please log in to view your image history.
					</Heading>
				</Center>
			</Box>
		);
	}

	return (
		<Box width="100%" px={4}>
			<Center mb={8}>
				<Heading as="h1" size="xl">
					History
				</Heading>
			</Center>
			<Box minHeight="50vh">
				{status === 'loading' ? (
					<p>Loading...</p>
				) : status === 'error' ? (
					<p>Error fetching images</p>
				) : (
					<ImageGallery
						images={allImages}
						fetchMoreImages={fetchNextPage}
						hasMoreImages={hasNextPage}
						isFetchingNextPage={isFetchingNextPage}
					/>
				)}
			</Box>
		</Box>
	);
};

export default HistoryPage;