export const FREE_TIER = "free";
export const BURSTING_TIER = "bursting";
export const STUFFED_TIER = "stuffed";

export const TIER_TO_TOKEN_REFRESH_AMOUNT = {
	[FREE_TIER]: 10,
	[STUFFED_TIER]: 1500,
	[BURSTING_TIER]: 5000,
}

export async function updateSubscription(access_token) {
	try {
		const response = await fetch('/api/update_subscription', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${access_token}`,
			}
		});

		if (!response.ok) {
			throw new Error('Failed to update subscription');
		}

		const data = await response.json();
		return data
	} catch (error) {
		console.error('Error updating subscription:', error);
		throw error
	}
}

export async function reset_premium_user_tokens(access_token) {
	try {
		const response = await fetch('/api/user_reset_premium_tokens', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${access_token}`,
			}
		});

		if (!response.ok) {
			throw new Error('Failed to update subscription');
		}

		const data = await response.json();
		console.log('Subscription updated:', data);
	} catch (error) {
		console.error('Error updating subscription:', error);
		throw error
	}
}

// Helper function to get the first of a month in EDT
function getFirstOfMonthEDT(year, month) {
	return new Date(`${year}-${month.toString().padStart(2, '0')}-01T00:00:00-04:00`);
}

// Helper function to get current date parts in EDT
function getCurrentDatePartsEDT() {
	const formatter = new Intl.DateTimeFormat('en-US', {
		timeZone: 'America/New_York',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false
	});

	const now = new Date();
	const [{ value: month }, , , , { value: year }] = formatter.formatToParts(now);
	return { year: parseInt(year, 10), month: parseInt(month, 10) };
}

export function getNextPremiumTokenResetDate() {
	const { year, month } = getCurrentDatePartsEDT();

	let nextMonth = month === 12 ? 1 : month + 1;
	let nextMonthYear = month === 12 ? year + 1 : year;

	return getFirstOfMonthEDT(nextMonthYear, nextMonth);
}

export function getLastPremiumTokenResetDate() {
	const { year, month } = getCurrentDatePartsEDT();
	return getFirstOfMonthEDT(year, month);
}

export function getNextFreeTokenResetDate() {
	// Create a formatter for EDT timezone
	const formatter = new Intl.DateTimeFormat('en-US', {
		timeZone: 'America/New_York',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false
	});

	// Get current date in EDT
	const now = new Date();
	const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] = formatter.formatToParts(now);

	// Create date object for next reset (start of next day in EDT)
	let resetDate = new Date(`${year}-${month}-${day}T00:00:00-04:00`);

	// If we've already passed today's reset time, set it to the next day
	if (hour !== '00' || minute !== '00') {
		resetDate.setDate(resetDate.getDate() + 1);
	}

	return resetDate;
}

export function isPremium(tier) {
	return (tier === BURSTING_TIER || tier === STUFFED_TIER);
} 