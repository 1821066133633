import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	VStack,
	Text,
	Button,
	Box,
	Heading,
	Flex,
	Center,
	Stack,
} from '@chakra-ui/react';
import { handlePatreonLink, getPatreonStuffedTierLink, getPatreonBurstingTierLink } from './patreonUtils';
import { premiumCrownColor } from './colors';
import TierChart from './TierChart';
import { CrownIcon } from './CustomIcons';

const PremiumModal = ({ isOpen, onClose, userId }) => {
	return (
		<Modal allowPinchZoom={true} isOpen={isOpen} onClose={onClose} isCentered size="xl">
			<ModalOverlay />
			<ModalContent>
				<Box textAlign="center" pt={6} pb={2}>
					<Flex justifyContent="center" alignItems="center">
						<Box as={CrownIcon} size={30} color={premiumCrownColor} mb={3} />
						<Heading as="h2" size="xl" mx={3}>
							Join Premium
						</Heading>
						<Box as={CrownIcon} size={30} color={premiumCrownColor} mb={3} />
					</Flex>
				</Box>
				<ModalCloseButton />
				<ModalBody>
					<VStack spacing={4} align="stretch" pb={4}>
						<Box
							bg="gray.50"
							bgColor="rgba(237, 242, 247, 0.1)"
							borderRadius="md"
							p={4}
							border="1px solid"
							borderColor="gray.200"
						>
							<Heading as="h3" size="lg" mb={4} textAlign="center">
								Tiers
							</Heading>
							<TierChart />
						</Box>
						<Text textAlign="center" fontWeight="bold" mb={-2} fontSize="xl">
							To join Premium, follow these steps:
						</Text>
						<VStack spacing={2} align="stretch">
							<VStack spacing={1} align="stretch">
								<Text fontSize="lg" textAlign="center" fontWeight="bold">1. Join a Patreon tier</Text>
								<Center>
									<Stack direction={["column", "row"]} spacing={2}>
										<Button
											as="a"
											href={getPatreonBurstingTierLink()}
											target="_blank"
											rel="noopener noreferrer"
											colorScheme="customGreen"
											height="auto"
											py={2}
										>
											<VStack spacing={0}>
												<Text>Join the</Text>
												<Text>$20 bursting tier</Text>
											</VStack>
										</Button>
										<Button
											as="a"
											href={getPatreonStuffedTierLink()}
											target="_blank"
											rel="noopener noreferrer"
											colorScheme="customGreen"
											height="auto"
											py={2}
										>
											<VStack spacing={0}>
												<Text>Join the</Text>
												<Text>$10 stuffed tier</Text>
											</VStack>
										</Button>
									</Stack>
								</Center>
							</VStack>
							<VStack spacing={1} align="stretch">
								<Text fontSize="lg" textAlign="center" fontWeight="bold">2. Link your account</Text>
								<Center>
									<Button
										onClick={() => handlePatreonLink(userId)}
										colorScheme="blue"
									>
										Link Your Patreon Account
									</Button>
								</Center>
							</VStack>
						</VStack>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default PremiumModal;