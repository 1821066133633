import React, { useEffect, useState } from 'react';
import { Button, Container, Flex, Heading, Text, VStack, useDisclosure, Box, Image, Center } from '@chakra-ui/react';
import AuthModal from './AuthModal';
import { supabase } from './index';
import { useNavigate } from 'react-router-dom';
import { BlueberryIcon, BalloonIcon } from './CustomIcons';
import { balloonColor, blueberryColor } from './colors';
import TierChart from './TierChart';

const ImageCarousel = () => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const images = [
		'https://d1yl8b3k2xxb93.cloudfront.net/homePage/4ee695d1.jpg',
		'https://d1yl8b3k2xxb93.cloudfront.net/homePage/fdaffew3.jpg',
		'https://d1yl8b3k2xxb93.cloudfront.net/homePage/feafafaf.jpg',
		'https://d1yl8b3k2xxb93.cloudfront.net/homePage/7d1031da.jpg'
	];

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Box overflow="hidden" borderRadius="md">
			<Image
				src={images[currentImageIndex]}
				alt={`Carousel image ${currentImageIndex + 1}`}
				objectFit="cover"
				width="100%"
				height="100%"
				transition="opacity 0.5s ease-in-out"
			/>
			<Text fontSize="xs" textAlign="center" color="grey" mt={0}>
				All images generated on spherify.ai
			</Text>
		</Box>
	);
};

const HomePage = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [session, setSession] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
		});

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
		});

		return () => subscription.unsubscribe();
	}, []);

	const handleButtonClick = () => {
		if (session) {
			navigate('/create');
		} else {
			onOpen();
		}
	};

	return (
		<Container maxW="container.xl" py={12}>
			<VStack spacing={12} align="center">
				<VStack align="center" spacing={6} maxW="lg">
					<Heading as="h1" size="2xl" textAlign="center">
						Welcome to spherify.ai
					</Heading>
					<Button colorScheme="blue" size="lg" onClick={handleButtonClick}>
						{session ? 'Get Started' : 'Sign up'}
					</Button>
				</VStack>

				<Center>
					<Flex direction={{ base: 'column', md: 'row' }} align="center" justify="center" maxW="1000px">
						<Box flex={1} mr={{ base: 0, md: 8 }} mb={{ base: 8, md: 0 }} maxW="500px">
							<Flex align="center" mb={4} whiteSpace="nowrap">
								<Heading as="h2" size="xl">Expand Your Fantasy</Heading>
								<BlueberryIcon boxSize={6} ml={2} color={blueberryColor} />
								<BalloonIcon boxSize={6} ml={1} color={balloonColor} />
							</Flex>
							<Text fontSize="lg">
								From burstingly full blueberries to pressurized air inflations, spherify.ai brings your wildest inflation fantasies to life! With an easy-to-use interface powered by AI-assisted image generation, transform your ideas into stunning, realistic images in seconds. Every image you create is completely yours — download it, share it, even sell it — you own what you create.
							</Text>
						</Box>
						<Box flex={1}>
							<ImageCarousel />
						</Box>
					</Flex>
				</Center>

				<Box width="100%">
					<Heading as="h2" size="xl" textAlign="center" mb={6}>
						Pricing and Tiers
					</Heading>
					<TierChart />
				</Box>
			</VStack>
			<AuthModal isOpen={isOpen} onClose={onClose} isCloseable={true} signingUp={true} />
		</Container>
	);
};

export default HomePage;