import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

function HamburgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <Box fontSize="sm">
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          onClick={onOpen}
          size="sm"
          fontSize="0.8em"
        />
        <MenuList>
          <MenuItem onClick={() => handleNavigate('/')}>Home</MenuItem>
          <MenuItem onClick={() => handleNavigate('/create')}>Image Generator</MenuItem>
          <MenuItem onClick={() => handleNavigate('/faq')}>FAQ</MenuItem>
          <MenuItem onClick={() => handleNavigate('/contentpolicy')}>Content Policy</MenuItem>
          <MenuItem onClick={() => handleNavigate('/termsofservice')}>Terms of Service</MenuItem>
          <MenuItem onClick={() => handleNavigate('/help')}>Help</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default HamburgerMenu;