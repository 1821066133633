import React, { useState, useEffect, useRef } from 'react';
import {
	Wrap,
	WrapItem,
	Button,
	Tooltip,
	IconButton,
	VStack,
	HStack,
	Input,
	useToast,
	Box,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Text,
	ModalHeader,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon, RepeatIcon, ChevronUpIcon, ChevronDownIcon, InfoIcon } from '@chakra-ui/icons';
import { supabase } from './index';
import { BACKSIDE_MODE, FACE_MODE, FULL_BODY_MODE, imageModeTypes, PremiumOnlyFeature } from './constants';
import { CrownIcon } from './CustomIcons';
import { modeInfo } from './modeInfo';
import { premiumCrownColor } from './colors';

export const presets = [
	{
		label: 'blueberry',
		prompt: "In this image, we see a blonde haired woman with pigtails in the end stages of a blueberry inflation transformation. Her skin is blue and her body has rounded out into a massive sphere, with her appendages being pulled into tight divots on her body. The camera is positioned close up on her face but slightly above, as her cheeks swell and her inflating, spherical body pushes into her swollen face. Her round body takes up the entire frame from side to side. Her spherical body is tightly covered in a dark blue, matte, fabric material that is her, once appropriately fitting but now, incredibly stretched out dress. The background is dimly lit, whimsical chocolate factory. The lights in the background gives a blurred bokeh effect that produces a cinematic vibe. ((Her face is being sucked into the divot on her body where her neck used to be.)) ((Her body is incredibly wide, to the point that you cannot see where her round body curves downwards. It is stretched from one side of the frame to the other in a very shallow curve, and the picture is cropped so you can't see the background to either side of her swollen body.)) ((Her body is at least twenty feet in circumference.))",
		width: 1024,
		height: 1024,
		imageType: FACE_MODE
	},
	{
		label: 'blueberry, widescreen',
		prompt: "In this image, a beautiful woman with brown hair and bangs is shown in the later stages of a blueberry inflation transformation. Her skin is blue with dark red blushing, and her face is tightly surrounded by her large, rounded body of which her head is sinking into. ((Her cheeks are fat and round as if she is holding her breath.)) ((She has an expression of pure embarrassment and shock.)) The camera is positioned below her, capturing the full extent of her transformation and the intensity of her facial expression as she rolls towards the camera. ((She is leaning forward and her hair is falling in front of her face.)) The background is blurry, dimly lit, and out of focus Factory, but with bright neon lights. The image appears to be from a movie where a character has inflated into a spherical blueberry. Her head is sinking into her body as she panics. ((Her spherical body is covered by a very tight, matte, dark blue fabric that appears to be her stretched out clothes without any wrinkles.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.)) ((The image has letterboxing as it is taken from a widescreen movie))",
		width: 1600,
		height: 1000,
		imageType: FACE_MODE
	},
	{
		label: 'blueberry, stuck',
		prompt: "In this image, we see the backside of a woman who is in the end stage of a blueberry inflation transformation, resulting in her body becoming a massive, dark blue, skin-textured spherical shape. Her clothes cling desperately to her body, completely stretched as they attempt to stay in one piece on her swelling body. To the sides of her crotch, are her swollen legs with her brown dress shoes being engulfed into her expanded form. She rests on her back, helplessly spherical, as she rolls back and her crotch points up towards the ceiling. Surrounding her, the environment suggests some kind of laboratory or workshop, with various pieces of equipment and machinery visible in the background. The scene takes place in a chocolate factory's inventing room which is evident by the scene being very brightly lit with whimsical colors. The image has a vertical aspect ratio as it appears to be from a phone video. The woman' shape is completely circular, resembling a massive twenty foot wide yoga ball. Her spherical body cannot even completely be in frame due to her sheer size. The woman is squeezed inside of a huge circular doorway that could fit an elephant, but cannot accommodate for her massive frame. Her body has a skin texture to it. The weight of her massive body causes her body to slightly protrude where it meets the floor.",
		width: 1600,
		height: 1000,
		imageType: BACKSIDE_MODE
	},
	{
		label: 'blueberry, goth, latex',
		prompt: 'In this image, a 20-year-old goth woman is shown in the later stages of a blueberry inflation transformation. ((She has straight black hair and very evenly cut bangs, giving her an edgy, goth look.)) Her face is tightly surrounded by her large, rounded body of which her head is sinking into. ((Her cheeks are tight and round as if she is holding in a very deep breath.)) ((She has an expression of extreme worry and pure embarrassment.)) (((The emo girl\'s eyes are extremely wide and bulging as she stares into the camera with a shoked expression))) (((The woman is wearing dark black eye shadow with bright red lipstick))) The camera is positioned above her as it captures her head sinking into the neck divot on her inflated body. ((Her skin is blue with very bright red blushing from embarrassment)) The background is a blurry, brightly lit, whimsical candy factory\'s inventing room, but with bright neon lights. The image appears to be from a movie where a character has inflated into an blueberry juice filled blimp, her spherical body taking up the entire frame from side to side. Her head is sinking into her body as her eyes dart around the room, searching for help. ((Her spherical body is covered by a very tight, red, shiny latex material with a straining zipper down the front. It appears to be her, once well-fitting but now completely taut stretched-out dress.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.)) ((The image has letterboxing as it is taken from a widescreen movie)), (((her face is sinking into the divot where her head sits, resulting in her body squeezing her face and cheeks.))) (((A finger reaches out and pokes her swollen body from behind the camera)))',
		width: 1600,
		height: 1000,
		imageType: FACE_MODE
	},
	{
		label: 'air, backside, floating on chocolate river',
		prompt: "In this image, we see the backside of a woman who is in the end stage of an inflation transformation, resulting in her body becoming a massive, skin-textured, spherical shape. She appears to be a massive, peach-colored, blimp. Her incredibly stretched-out red, matte, fabric dress clings tightly to her body, constricting her air-filled body. To the sides of her crotch are her swollen legs with her brown dress shoes being engulfed into her expanded form. She rests on her back, helplessly spherical as she drifts lazily in a very wide, dark chocolate river. The river is surrounded by a industrial nature looking space, but instead of flora, the plants, grass, and trees are fantastical candy decorations. The entire scene is very dimly lit, but has a dark cinematic lighting with out-of-focus bokeh. The image has letterboxing as it is a screenshot from a widescreen formatted movie. ((The woman' shape is completely circular, resembling a massive twenty foot wide yoga ball.)) ((Her body has a skin texture to it.))",
		width: 1600,
		height: 1000,
		imageType: BACKSIDE_MODE
	},
	{
		label: 'top-down, body takes up entire frame',
		prompt: "((Deep blue indigo skin, blue skinned character.)) In this image, a beautiful woman with a blue face and blue hair and bangs is shown in the later stages of a blueberry inflation transformation. Her body takes up the entire frame like a blue wall, her tiny head centered in the frame. Her skin is blue with dark red blushing, and her face is tightly surrounded by her large, rounded body of which her head is sinking into. She has beautiful eyes looking upward. ((Her face is beautiful and gorgeous, but surprised and shocked.)) ((She has an expression of pure embarrassment and shock.)) The camera looks down on her, she looks up at us, her head is tiny compared to her gigantic body. ((She is leaning forward and her hair is falling in front of her face.)). ((Her spherical body is covered by a very tight, matte, dark blue fabric that appears to be her stretched out clothes without any wrinkles.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.))",
		width: 1200,
		height: 1200,
		imageType: FACE_MODE,
		info: 'Created by hgswells9000 - check out their work at https://www.deviantart.com/hgswells9000'
	},
	{
		label: 'blueberry, nerdy woman, being examined',
		prompt: `In this image, we see a young woman in the end stages of a blueberry inflation transformation. Her body has rounded out into a massive spherical shape, with a rich, dark blue color to it. The woman's inflated body is upright, resting on her swollen crotch and facing directly towards the camera. Her body is nearly completely spherical except for her massive breasts which are incredibly swollen, and her nipples incredibly erect and lazily leaking juice. The woman has brown, shoulder length hair with bangs that is disheveled and messy on her swollen body. Her head is sinking into her body, her incredibly swollen body pushing up into her face and squishing her round, inflated cheeks from below. The woman has an expression of surprise and fear as she can worries that she might explode from the pressure. She has a nerdy aesthetic about her, evident by her thick brimmed glasses. The woman's once well fitting red dress is now split at the seams and tattered on her body. Her expanding body is bursting through the fabric as it swells. Her entire body, including her face, is a blue color with a slight red and purple hue to it. ((The woman's body is easily 20 feet in diameter.))

The woman is surrounded by a group of small women that have mixed expressions ranging from shock and surprise to laughter and teasing. Some point at her and poke her, testing her tautness.

The background is a dimly lit chocolate factory which contains large, futuristic machinery and red neon lights. The woman who has undergone a blueberry inflation transformation is being harshly lit by the red neon lights, casting a cinematic lighting on her. The atmosphere is slightly foggy, giving a dark cinematic vibe.`,
		width: 1400,
		height: 1100,
		imageType: FULL_BODY_MODE,
	},
	{
		label: 'air, blonde, about to be squeezed',
		prompt: `In this highly detailed and vibrant photograph, we see a woman in the end stages of an air inflation transformation. Her entire body has rounded out into a massive spherical shape. The woman's round body is upright but leaning forward slightly on her stomach in a 3/4 view to the right. (((She is desperately flapping her swollen hands to signal for help as two large, flat metal presses on either side of her clamp down on her body, squeezing her tightly.))) Behind her is a bright red neon sign that reads "Squeezing Room", indicating that the two large presses will squeeze her to attempt to get the air out. The woman's breasts are massive, easily 4 feet in circumference each. Her pink tank top is hardly holding on, nearly bursting at the seams. The tank top now only just barely covers her enormous breasts, causing a very deep amount of cleavage showing through the top of the shirt. The woman's face is one of desperation as her eyes are wide, eyeing the massive presses that she is moving towards. She has long blonde hair, and appears that she was beautiful and popular before air inflation transformation. ((The woman's body is incredibly massive and spherical, measuring 20 feet in diameter))

The background is a brightly lit, vibrant and colorful, futuristic factory, with large shiny pipes and machines running in the back of the image. The image is incredibly clear and crisp as it is from a high resolution 4k movie.`,
		width: 1400,
		height: 1100,
		imageType: FULL_BODY_MODE,
	},
	{
		label: 'blueberry, blonde, in Juicing room, being rolled',
		prompt: `In this highly detailed and vibrant photograph, we see a woman in the end stages of an blueberry inflation transformation. Her entire body has rounded out into a massive spherical shape. The woman's round body is upright but leaning forward slightly on her stomach in a 3/4 view to the right. ((She is desperately flapping her swollen hands to signal for help as two female oompa loompas roll her forward from behind. The oompa loompas appear to be exerting a lot of effort as they tirelessly roll the spherically inflated woman. The blueberry inflated woman towers over the small oompa loompas. Behind her is a bright red neon sign that reads "Juicing Room", indicating that they will squeeze the juice out of her in this room.)) The woman's breasts are massive, easily 4 feet in circumference each. Her pink tank top is hardly holding on, nearly bursting at the seams. The tank top now only just barely covers her enormous breasts, causing a very deep amount of cleavage showing through the top of the shirt. ((You can see dark wet stains on her shirt where her nipples are profusely leaking juice, along with dark blue syrup pouring out from under her shirt.)) The woman's face is one of desperation as her eyes are wide, eyeing the massive presses that she is moving towards. She has long blonde hair with a part in the middle, her hair messily falling in front of her face. Her cheeks are massively swollen and round. ((The woman's body is incredibly massive and spherical, measuring 20 feet in diameter.)) ((Her entire body is a dark blue color, including her face.))

The background is a brightly lit, vibrant and colorful, futuristic factory, with large shiny pipes and machines running in the back of the image. The image is incredibly clear and crisp as it is from a high resolution 4k movie.`,
		width: 1400,
		height: 1100,
		imageType: FULL_BODY_MODE,
	},
];

const PremiumModalHeader = ({ title, isPremium }) => {
	return (
		<ModalHeader p={4} fontSize={32}>
			<HStack
				spacing={4}
				justify="center"
				align="center"
				w="100%"
			>
				{isPremium && <CrownIcon size={24} color={premiumCrownColor} />}
				<span>{title}</span>
				{isPremium && <CrownIcon size={24} color={premiumCrownColor} />}
			</HStack>
		</ModalHeader>
	);
};

const PresetSelector = ({
	selectedMode,
	onModeSelect,
	selectedPreset,
	onPresetSelect,
	currentSettings,
	onCustomPresetsChange
}) => {
	const [customPresets, setCustomPresets] = useState([]);
	const [renameIndex, setRenameIndex] = useState(null);
	const [newName, setNewName] = useState('');
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
	const [presetToDelete, setPresetToDelete] = useState(null);
	const [selectedModeInfo, setSelectedModeInfo] = useState(null);
	const [collapsedSections, setCollapsedSections] = useState({
		[FACE_MODE]: false,
		[BACKSIDE_MODE]: true,
		[FULL_BODY_MODE]: true,
	});
	const effectRan = useRef(false);
	const cancelRef = useRef();
	const toast = useToast();

	// Group presets by type
	const groupedPresets = {
		[FACE_MODE]: presets.filter(preset => preset.imageType === FACE_MODE),
		[BACKSIDE_MODE]: presets.filter(preset => preset.imageType === BACKSIDE_MODE),
		[FULL_BODY_MODE]: presets.filter(preset => preset.imageType === FULL_BODY_MODE),
	};

	// Group custom presets by type
	const groupedCustomPresets = {
		[FACE_MODE]: customPresets.filter(preset => preset.image_type === FACE_MODE),
		[BACKSIDE_MODE]: customPresets.filter(preset => preset.image_type === BACKSIDE_MODE),
		[FULL_BODY_MODE]: customPresets.filter(preset => preset.image_type === FULL_BODY_MODE),
	};

	useEffect(() => {
		if (effectRan.current === false) {
			fetchCustomPresets();
			return () => {
				effectRan.current = true;
			};
		}
	}, []);

	const fetchCustomPresets = async () => {
		const { data: userData, error: userError } = await supabase.auth.getUser();
		if (userError) {
			console.error('Error fetching current user:', userError);
			return;
		}

		const { data, error } = await supabase
			.from('image_presets')
			.select()
			.eq("user_id", userData.user.id)
			.order('id');

		if (error) {
			console.error('Error fetching custom presets:', error);
			return;
		}

		setCustomPresets(data);
		onCustomPresetsChange(data);
	};

	const handleCreateNewPreset = async (imageType) => {
		const { data: userData, error: userError } = await supabase.auth.getUser();
		if (userError) {
			console.error('Error fetching current user:', userError);
			return;
		}

		const { data, error } = await supabase
			.from('image_presets')
			.insert({
				name: `New ${imageType} Preset`,
				user_id: userData.user.id,
				image_type: imageType,
				prompt: currentSettings.prompt,
				width: currentSettings.width,
				height: currentSettings.height,
			})
			.select()
			.single();

		if (error) {
			toast({
				title: 'Error',
				description: 'Failed to create new preset',
				status: 'error',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		} else {
			setCustomPresets([...customPresets, data]);
			onCustomPresetsChange([...customPresets, data]);
			toast({
				title: 'Success',
				description: 'New preset created',
				status: 'success',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		}
	};

	const handleDeletePreset = async () => {
		if (!presetToDelete) return;

		const { error } = await supabase
			.from('image_presets')
			.delete()
			.eq('id', presetToDelete.id);

		if (error) {
			toast({
				title: 'Error',
				description: 'Failed to delete preset',
				status: 'error',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		} else {
			const updatedPresets = customPresets.filter(p => p.id !== presetToDelete.id);
			setCustomPresets(updatedPresets);
			onCustomPresetsChange(updatedPresets);
			toast({
				title: 'Success',
				description: 'Preset deleted successfully',
				status: 'success',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		}
		setIsDeleteAlertOpen(false);
		setPresetToDelete(null);
	};

	const openDeleteAlert = (preset) => {
		setPresetToDelete(preset);
		setIsDeleteAlertOpen(true);
	};

	const handleRename = async (preset) => {
		if (newName.trim() === '') {
			toast({
				title: 'Error',
				description: 'Preset name cannot be empty',
				status: 'error',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
			return;
		}

		const { error } = await supabase
			.from('image_presets')
			.update({ name: newName })
			.eq('id', preset.id);

		if (error) {
			toast({
				title: 'Error',
				description: 'Failed to rename preset',
				status: 'error',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		} else {
			const updatedPresets = customPresets.map(p =>
				p.id === preset.id ? { ...p, name: newName } : p
			);
			setCustomPresets(updatedPresets);
			onCustomPresetsChange(updatedPresets);
			setRenameIndex(null);
			setNewName('');
			toast({
				title: 'Success',
				description: 'Preset renamed successfully',
				status: 'success',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		}
	};

	const handleOverwrite = async (preset) => {
		const { error } = await supabase
			.from('image_presets')
			.update({
				prompt: currentSettings.prompt,
				width: currentSettings.width,
				height: currentSettings.height,
				image_type: currentSettings.selectedLora,
			})
			.eq('id', preset.id);

		if (error) {
			toast({
				title: 'Error',
				description: 'Failed to overwrite preset',
				status: 'error',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		} else {
			const updatedPresets = customPresets.map(p =>
				p.id === preset.id
					? {
						...p,
						prompt: currentSettings.prompt,
						width: currentSettings.width,
						height: currentSettings.height,
						image_type: currentSettings.selectedLora,
					}
					: p
			);
			setCustomPresets(updatedPresets);
			onCustomPresetsChange(updatedPresets);
			toast({
				title: 'Success',
				description: 'Preset overwritten successfully',
				status: 'success',
				duration: 3000,
				position: "top",
				isClosable: true,
			});
		}
	};

	const toggleSection = (typeId) => {
		setCollapsedSections(prev => ({
			...prev,
			[typeId]: !prev[typeId]
		}));
	};

	const expandSection = (typeId) => {
		setCollapsedSections(prev => ({
			...prev,
			[typeId]: false,
		}))
	}

	return (
		<VStack align="stretch" spacing={8} width="100%">
			{imageModeTypes.map(type => (
				<Box key={type.id} width="100%">
					<HStack spacing={1} mb={2} align="center" justify="flex-start">
						<Button
							onClick={() => {
								expandSection(type.id)
								onModeSelect(type.id)
							}}
							colorScheme={selectedMode === type.id ? 'green' : 'gray'}
							size="md"
							width="140px"
						>
							{type.label + " Mode"}
						</Button>
						<Tooltip label="Click to find out more info about this mode">
							<IconButton
								icon={<InfoIcon />}
								size="xs"
								variant="ghost"
								onClick={() => setSelectedModeInfo(type.id)}
								aria-label={`Info about ${type.label} mode`}
							/>
						</Tooltip>
						{type.isPremium && (
							<Tooltip label={PremiumOnlyFeature}>
								<Box>
									<CrownIcon boxSize={5} color="gold" />
								</Box>
							</Tooltip>
						)}
						<Button
							rightIcon={collapsedSections[type.id] ? <ChevronDownIcon /> : <ChevronUpIcon />}
							onClick={() => toggleSection(type.id)}
							size="xs"
							variant="ghost"
							iconSpacing={1}
						>
							Presets
						</Button>
					</HStack>

					<Box
						pb={0}
						display={collapsedSections[type.id] ? 'none' : 'block'}
						width="100%"
					>
						<Wrap spacing={2}>
							{/* Built-in presets */}
							{groupedPresets[type.id].map((preset, index) => (
								<WrapItem key={`builtin-${type.id}-${index}`}>
									<Tooltip label={preset.info} isDisabled={!preset.info}>
										<Button
											onClick={() => onPresetSelect(presets.indexOf(preset))}
											colorScheme={selectedPreset === presets.indexOf(preset) ? 'blue' : 'gray'}
											size="sm"
										>
											{preset.label}
										</Button>
									</Tooltip>
								</WrapItem>
							))}

							{/* Custom user-created presets */}
							{groupedCustomPresets[type.id].map((preset) => (
								<WrapItem key={`custom-${preset.id}`}>
									<Box>
										{renameIndex === preset.id ? (
											<VStack spacing={1}>
												<Input
													value={newName}
													onChange={(e) => setNewName(e.target.value)}
													placeholder="New name"
													size="sm"
												/>
												<HStack>
													<Button size="xs" onClick={() => handleRename(preset)}>Save</Button>
													<Button size="xs" onClick={() => setRenameIndex(null)}>Cancel</Button>
												</HStack>
											</VStack>
										) : (
											<VStack spacing={1}>
												<Button
													onClick={() => onPresetSelect(presets.length + customPresets.indexOf(preset))}
													colorScheme={selectedPreset === (presets.length + customPresets.indexOf(preset)) ? 'blue' : 'gray'}
													size="sm"
												>
													{preset.name}
												</Button>
												<HStack spacing={1}>
													<Tooltip label="Rename">
														<IconButton
															icon={<EditIcon />}
															size="xs"
															onClick={() => {
																setRenameIndex(preset.id);
																setNewName(preset.name);
															}}
														/>
													</Tooltip>
													<Tooltip label="Overwrite With Current Settings">
														<IconButton
															icon={<RepeatIcon />}
															size="xs"
															onClick={() => handleOverwrite(preset)}
														/>
													</Tooltip>
													<Tooltip label="Delete">
														<IconButton
															icon={<DeleteIcon />}
															size="xs"
															onClick={() => openDeleteAlert(preset)}
														/>
													</Tooltip>
												</HStack>
											</VStack>
										)}
									</Box>
								</WrapItem>
							))}

							{/* Add new preset button */}
							<WrapItem>
								<Tooltip label="New preset with current settings">
									<IconButton
										icon={<AddIcon />}
										size="sm"
										onClick={() => handleCreateNewPreset(type.id)}
									/>
								</Tooltip>
							</WrapItem>
						</Wrap>
					</Box>
				</Box>
			))}

			{/* Info Modal */}
			<Modal
				isOpen={selectedModeInfo !== null}
				onClose={() => setSelectedModeInfo(null)}
				size="xl"
				allowPinchZoom={true}
			>
				<ModalOverlay />
				<ModalContent>
					<PremiumModalHeader
						title={selectedModeInfo && modeInfo[selectedModeInfo].title}
						isPremium={selectedModeInfo && modeInfo[selectedModeInfo].is_premium}
					/>
					<ModalCloseButton />
					<ModalBody>
						{selectedModeInfo && (
							<>
								{modeInfo[selectedModeInfo].content}
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => setSelectedModeInfo(null)}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Delete Confirmation Dialog */}
			<AlertDialog
				isOpen={isDeleteAlertOpen}
				leastDestructiveRef={cancelRef}
				onClose={() => setIsDeleteAlertOpen(false)}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Preset
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure you want to delete this preset? This action cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
								Cancel
							</Button>
							<Button colorScheme="red" onClick={handleDeletePreset} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</VStack>
	);
};

export default PresetSelector;