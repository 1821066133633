const nonReportableErrorMessages = {
	"Invalid login credentials": true,
	"Email not confirmed": true,
}

export function isNonReportableError(error) {
	if (error?.message?.includes("For security purposes, you can only request this after")) {
		return true
	}
	return !!nonReportableErrorMessages[error?.message];
}