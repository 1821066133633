import { extendTheme } from "@chakra-ui/react";

export const tokenColor = "blue.300"
export const estimatedTimeColor = "#e0614a"
export const premiumCrownColor = "gold"
export const blueberryColor = "blue.500"
export const balloonColor = "red.500"

// Define your custom colors
const colors = {
	customGreen: {
		"50": "#E5FFF2",
		"100": "#B8FFDB",
		"200": "#8AFFC3",
		"300": "#5CFFAC",
		"400": "#2EFF95",
		"500": "#00FF7D",
		"600": "#00CC64",
		"700": "#00994B",
		"800": "#006632",
		"900": "#003319"
	},
};

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};


// Extend the theme
export const theme = extendTheme({ config, colors });