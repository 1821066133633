import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ChakraProvider, ColorModeScript, Box, useDisclosure } from '@chakra-ui/react';
import { supabase } from './index';
import CreatePage from './CreatePage';
import AuthModal from './AuthModal';
import Header from './Header';
import HelpPage from './HelpPage';
import Footer from './Footer';
import ContentPolicyPage from './ContentPolicyPage';
import TermsOfServicePage from './TermsOfServicePage';
import HomePage from './HomePage';
import NotFoundPage from './NotFoundPage';
import FAQPage from './FAQPage';
import { Provider, ErrorBoundary, RollbarContext } from '@rollbar/react';
import { theme } from './colors';
import HistoryPage from './HistoryPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import SettingsPage from './SettingsPage';
import PatreonLinkingPage from './PatreonLinkingPage';
import useAuthListener from './authListener';
import ResetPassword from './ResetPassword';
import FavoritesPage from './favoritesPage';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_VERCEL_ENV,
  payload: {}
};

const queryClient = new QueryClient();

function AppContent() {
  const [session, setSession] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isCreatePage = location.pathname === '/create';
  const publicPages = ['/help', '/contentpolicy', '/termsofservice', '/', '/404', '/faq'];
  const isPublicPage = publicPages.includes(location.pathname);
  useAuthListener();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!session && !isPublicPage) {
      onOpen();
    } else {
      onClose();
    }
  }, [session, isPublicPage, onOpen, onClose]);

  return (
    <>
      <Header session={session} onOpenAuthModal={onOpen} />
      <Box
        bg="gray.800"
        minH="100vh"
        color="white"
        filter={!session && !isPublicPage ? "blur(5px)" : "none"}
        pt="20px"
      >
        <Routes>
          <Route caseSensitive path="/" element={
            <RollbarContext context="/home">
              <HomePage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/create" element={
            <RollbarContext context="/create">
              <CreatePage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/history" element={
            <RollbarContext context="/history">
              <HistoryPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/favorites" element={
            <RollbarContext context="/favorites">
              <FavoritesPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/account" element={
            <RollbarContext context="/account">
              <SettingsPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/help" element={
            <RollbarContext context="/help">
              <HelpPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/contentpolicy" element={
            <RollbarContext context="/contentpolicy">
              <ContentPolicyPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/termsofservice" element={
            <RollbarContext context="/termsofservice">
              <TermsOfServicePage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/faq" element={
            <RollbarContext context="/faq">
              <FAQPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/patreon-linking" element={
            <RollbarContext context="/patreon-linking">
              <PatreonLinkingPage />
            </RollbarContext>
          } />
          <Route caseSensitive path="/reset-password" element={
            <RollbarContext context="/reset-password">
              <ResetPassword />
            </RollbarContext>
          } />
          <Route caseSensitive path="/404" element={
            <RollbarContext context="/404">
              <NotFoundPage />
            </RollbarContext>
          } />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Box>
      <Footer isCreatePage={isCreatePage} />
      <AuthModal isOpen={isOpen} onClose={onClose} isCloseable={isPublicPage} />
    </>
  );
}

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Router>
              <AppContent />
            </Router>
          </ChakraProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;