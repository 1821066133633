import React from 'react';
import { Box, Container, Stack, Text, Link, useColorModeValue, HStack, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { socialLinks } from './constants';

function Footer({ isCreatePage }) {
	return (
		<Box
			bg={useColorModeValue('gray.50', 'gray.900')}
			color={useColorModeValue('gray.700', 'gray.200')}
			pb={isCreatePage ? '80px' : '0'} // Add padding when on CreatePage
		>
			<Container
				as={Stack}
				maxW={'6xl'}
				py={4}
				direction={{ base: 'column', md: 'row' }}
				spacing={4}
				justify={{ base: 'center', md: 'space-between' }}
				align={{ base: 'center', md: 'center' }}
			>
				<Stack direction={'row'} spacing={6}>
					<ChakraLink as={RouterLink} to="/faq">FAQ</ChakraLink>
					<ChakraLink as={RouterLink} to="/help">Help</ChakraLink>
					<ChakraLink as={RouterLink} to="/contentpolicy">Content Policy</ChakraLink>
					<ChakraLink as={RouterLink} to="/termsofservice">Terms of Service</ChakraLink>
				</Stack>
				<Stack direction={'row'} spacing={6}>
					<Link key={socialLinks[0].name} href={socialLinks[0].url} isExternal>
						<HStack>
							<Icon as={socialLinks[0].icon} boxSize={6} />
							<Text>{socialLinks[0].name}</Text>
						</HStack>
					</Link>
				</Stack>
			</Container>
		</Box>
	);
}

export default Footer;