import { useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { usePostHog } from 'posthog-js/react'
import { useRollbar } from '@rollbar/react';
import { updateSubscription } from './subscription_utils';

const supabase = createClient(
	process.env.REACT_APP_SUPABASE_API_URL,
	process.env.REACT_APP_SUPABASE_ANON_KEY
);

function useAuthListener() {
	const posthog = usePostHog();
	const rollbar = useRollbar();

	const updateUserInfo = useCallback(async (session) => {
		const user = session.user
		if (user) {
			posthog.identify(user.email);
			rollbar.configure({
				payload: {
					person: {
						id: user.id,
						email: user.email
					}
				}
			});
			try {
				await updateSubscription(session.access_token);
			} catch (error) {
				rollbar.error(error)
			}
		}
	}, [posthog, rollbar]);

	useEffect(() => {
		// Check for existing session on mount
		supabase.auth.getSession().then(({ data: { session } }) => {
			if (session?.user) {
				updateUserInfo(session);
			}
		});

		// Set up listener for future auth changes
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((event, session) => {
			if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
				if (session?.user) {
					updateUserInfo(session);
				}
			}
		});

		// Cleanup subscription on unmount
		return () => {
			subscription.unsubscribe();
		};
	}, [updateUserInfo]);

	return null; // This hook doesn't render anything
}

export default useAuthListener;