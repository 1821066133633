import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import { useInfiniteQuery } from 'react-query';
import ImageGallery from './ImageGallery';
import { supabase } from './index';
import { convertToCloudFrontURL } from './imageUtils';

const fetchFavoriteImages = async ({ pageParam = 0 }) => {
	const pageSize = 10;
	const { data: { user } } = await supabase.auth.getUser();

	if (!user) {
		throw new Error('User not authenticated');
	}

	const { data, error } = await supabase
		.from('fav_images')
		.select()
		.eq('user_id', user.id)
		.order('favorited_at', { ascending: false })
		.range(pageParam * pageSize, (pageParam + 1) * pageSize - 1);

	if (error) {
		throw new Error('Error fetching favorite images: ' + error.message);
	}

	const convertedData = data.map(item => ({
		...item,
		image_url: convertToCloudFrontURL(item.image_url),
		isFavorited: true,
		favoritedId: item.id
	}));

	return convertedData;
};

const FavoritesPage = () => {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
	} = useInfiniteQuery('favoriteImages', fetchFavoriteImages, {
		getNextPageParam: (lastPage, pages) => {
			return lastPage.length === 0 ? undefined : pages.length;
		},
	});

	const allImages = data ? data.pages.flat() : [];

	return (
		<Box width="100%" px={4}>
			<Center mb={8}>
				<Heading as="h1" size="xl">
					Favorites
				</Heading>
			</Center>
			<Box minHeight="50vh">
				{status === 'loading' ? (
					<p>Loading...</p>
				) : status === 'error' ? (
					<p>Error fetching favorite images</p>
				) : (
					<ImageGallery
						images={allImages}
						fetchMoreImages={fetchNextPage}
						hasMoreImages={hasNextPage}
						isFetchingNextPage={isFetchingNextPage}
					/>
				)}
			</Box>
		</Box>
	);
};

export default FavoritesPage;